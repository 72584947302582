import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AccessTokenContext } from '@docsys/auth';
import { Id } from '@docsys/common-types';
import { ButtonSet, LinkButton, LinkButtonPriority, Loading, LoadingVariant } from '@docsys/controls';
import { PreviousPageIcon } from '@docsys/icons';
import { ClientResponse, ClientsEndpoint, DetailedTenantResponse, ErrorResult, TenantsEndpoint } from '~/controllers';

import { ErrorPage } from '../../error';
import { TenantsPage } from '../page';
import { UpdateTenantDetails } from './form';

export const TenantDetails: React.VFC = () => {
	const {
		id,
	} = useParams();

	const tokenProvider = React.useContext(AccessTokenContext);
	const [details, setDetails] = React.useState<DetailedTenantResponse | null>(null);
	const [clients, setClients] = React.useState<ClientResponse[] | null>(null);
	const [error, setError] = React.useState<ErrorResult | null>(null);

	React.useEffect(() => {
		const tenantsEndpoint = new TenantsEndpoint(tokenProvider);
		const clientsEndpoint = new ClientsEndpoint(tokenProvider);

		tenantsEndpoint.getTenantDetails(id as Id)
			.then(response => {
				if (response.ok) {
					setDetails(response);
				}
				else {
					setError(response);
				}
			});

		clientsEndpoint.getAllClients()
			.then(response => {
				if (response.ok) {
					setClients(response.clients);
				}
				else {
					setError(response);
				}
			});
	}, [id, tokenProvider]);

	if (error) {
		return <ErrorPage error={error} />
	}

	return <TenantsPage>
		<ButtonSet>
			<LinkButton
				icon={PreviousPageIcon}
				label={'Terug naar overzicht'}
				priority={LinkButtonPriority.Tertiary}
				to={'/tenants'}
			/>
		</ButtonSet>
		<hr/>
		{ (details && clients)
			? <UpdateTenantDetails details={details} clients={clients} />
			: <Loading label={'Laden...'} variant={LoadingVariant.Medium} />
		}
	</TenantsPage>;
};
