import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AccessTokenContext } from '@docsys/auth';
import {
	Button,
	ButtonPriority,
	ButtonSet,
	ButtonType,
	Form,
	FormSeparator,
	FormTextField,
	H2,
	Loading,
	LoadingVariant,
	Panel,
	PanelPriority,
} from '@docsys/controls';
import { useForm } from '@docsys/hooks';
import { AddIcon, CancelIcon } from '@docsys/icons';

import { CreateScopeRequest, ErrorResult, ScopesEndpoint } from '~/controllers';

import { ErrorPage } from '../../error';
import { ScopesPage } from '../page';
import { scopeValidator } from '../validation';

export const AddScope: React.VFC = () => {
	const tokenProvider = React.useContext(AccessTokenContext);
	const scopesEndpoint = React.useMemo(() => new ScopesEndpoint(tokenProvider), [tokenProvider]);

	const [currentScopes, setCurrentScopes] = React.useState<string[] | null>(null);
	const [done, setDone] = React.useState<boolean>(false);
	const [error, setError] = React.useState<ErrorResult | null>(null);

	React.useEffect(() => {
		const asyncEffect = async () => {
			const result = await scopesEndpoint.getAllScopes();
			setCurrentScopes(result.ok && result.scopes.map(s => s.name) || []);
		};

		asyncEffect();
	}, [scopesEndpoint]);

	const navigate = useNavigate();

	const form = useForm<CreateScopeRequest>({
		initialData: {
			name: '',
		},
		validations: {
			name: [scopeValidator(currentScopes || [])],
		},
		submit: async (data) => {
			try {
				setDone(true);
				const response = await scopesEndpoint.createScope(data);

				if (response.ok) {
					navigate('/scopes/scope/' + response.name);
				}
				else {
					setDone(false);
				}

				return response;
			}
			catch (e) {
				setError(e as ErrorResult);
				throw e;
			}
		},
	});

	if (error) {
		return <ErrorPage error={error} />
	}

	if (currentScopes === null) {
		return <Loading label={'Laden...'} variant={LoadingVariant.Medium} />;
	}

	return <ScopesPage>
		<Panel priority={PanelPriority.Primary}>
			<Form readonly={done} onSubmit={form.submit}>
				<H2 first>Scope toevoegen</H2>
				<FormTextField
					label='Naam:'
					entry='name'
					form={form}
				/>
				<FormSeparator />
				<ButtonSet>
					<Button
						busy={form.busy}
						disabled={form.invalid}
						icon={AddIcon}
						label='Toevoegen'
						priority={ButtonPriority.Primary}
						type={ButtonType.Submit}
					/>
					<Button
						icon={CancelIcon}
						label='Annuleren'
						onClick={() => navigate(-1)}
						priority={ButtonPriority.Secondary}
						type={ButtonType.Button}
					/>
				</ButtonSet>
			</Form>
		</Panel>
	</ScopesPage>;
};
