import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonPriority, ButtonType, useToast } from '@docsys/controls';
import { DeleteIcon } from '@docsys/icons';

import { ClientResponse, ScopeResponse } from '~/controllers';

import { DeleteScopeDialog } from './delete-scope-dialog';

interface DeleteScopeButtonProps {
	clients: ClientResponse[],
	scope: ScopeResponse,
}

export const DeleteScopeButton: React.VFC<DeleteScopeButtonProps> = (props) => {
	const {
		clients,
		scope,
	} = props;

	const { showToast } = useToast();

	const [open, setOpen] = React.useState<boolean>(false);
	const navigate = useNavigate();

	const handleButtonClick = () => {
		setOpen(true);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	const handleDeleted = (name: string) => {
		setOpen(false);

		showToast({
			text: `Scope "${name}" is verwijderd.`,
		});

		window.setTimeout(() => {
			navigate('/scopes');
		}, 500);
	};

	return <>
		<Button
			icon={DeleteIcon}
			label={'Verwijderen'}
			priority={ButtonPriority.SecondaryDanger}
			onClick={handleButtonClick}
			type={ButtonType.Button}
		/>
		<DeleteScopeDialog
			open={open}
			clients={clients}
			scope={scope}
			onCancel={handleCancel}
			onDeleted={handleDeleted}
		/>
	</>;
};