import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AccessTokenContext } from '@docsys/auth';
import {
	Button,
	ButtonPriority,
	ButtonSet,
	ButtonType,
	Form,
	FormSeparator,
	FormTextField,
	H2,
	Panel,
	PanelPriority,
} from '@docsys/controls';
import { useForm } from '@docsys/hooks';
import { AddIcon, CancelIcon } from '@docsys/icons';

import { displayNameValidator } from '~/components';
import { CreateClientRequest, ClientsEndpoint } from '~/controllers';

import { ErrorPage } from '../../error';
import { ClientsPage } from '../page';
import { AddClientProps } from './index.types';
import { SecretsDialog, SecretsDialogProps } from './secrets-dialog';

export const AddClient: React.VFC<AddClientProps> = (props) => {
	const {
		type,
	} = props;

	const tokenProvider = React.useContext(AccessTokenContext);
	const clientsEndpoint = new ClientsEndpoint(tokenProvider);
	const [error, setError] = React.useState<Error | null>(null);
	const [done, setDone] = React.useState<boolean>(false);
	const [dialogState, setDialogState] = React.useState<Omit<SecretsDialogProps, 'open'> | null>(null);

	const navigate = useNavigate();

	const form = useForm<CreateClientRequest>({
		initialData: {
			displayName: '',
			type,
		},
		validations: {
			displayName: [displayNameValidator],
		},
		submit: async (data) => {
			try {
				setDone(true);
				const response = await clientsEndpoint.createClient(data);

				if (response.ok) {
					if (response.secret) {
						setDialogState({
							displayName: data.displayName,
							secret: response.secret,
							onDone: () => {
								setDialogState(null);

								// Give the dialog time do hide.
								window.setTimeout(() => {
									navigate('/clients/client/' + response.id);
								}, 500);
							},
						});
					}
					else {
						navigate('/clients/client/' + response.id);
					}
				}

				return response;
			}
			catch (e) {
				setError(e as Error);
				throw e;
			}
		},
	});

	if (error) {
		return <ErrorPage error={error} />
	}

	return <ClientsPage>
		<Panel priority={PanelPriority.Primary}>
			<Form readonly={done} onSubmit={form.submit}>
				{ type === 'frontend' && <H2 first>Frontend client toevoegen</H2>}
				{ type === 'backend' && <H2 first>Backend client toevoegen</H2>}
				<FormTextField
					label='Weergavenaam:'
					entry='displayName'
					form={form}
				/>
				<FormSeparator />
				<ButtonSet>
					<Button
						busy={form.busy}
						disabled={form.invalid}
						icon={AddIcon}
						label='Toevoegen'
						priority={ButtonPriority.Primary}
						type={ButtonType.Submit}
					/>
					<Button
						icon={CancelIcon}
						label='Annuleren'
						onClick={() => navigate(-1)}
						priority={ButtonPriority.Secondary}
						type={ButtonType.Button}
					/>
				</ButtonSet>
			</Form>
		</Panel>
		<SecretsDialog
			open={!!dialogState}
			{...dialogState}
		/>
	</ClientsPage>;
};
