import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonPriority, ButtonType, useToast } from '@docsys/controls';
import { DeleteIcon } from '@docsys/icons';

import { ClientResponse } from '~/controllers';

import { DeleteClientDialog } from './delete-client-dialog';

interface DeleteClientButtonProps {
	client: ClientResponse,
}

export const DeleteClientButton: React.VFC<DeleteClientButtonProps> = (props) => {
	const {
		client,
	} = props;

	const { showToast } = useToast();

	const [open, setOpen] = React.useState<boolean>(false);
	const navigate = useNavigate();

	const handleButtonClick = () => {
		setOpen(true);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	const handleDeleted = (displayName: string) => {
		setOpen(false);

		showToast({
			text: `Client "${displayName}" is verwijderd.`,
		});

		window.setTimeout(() => {
			navigate('/clients');
		}, 500);
	};

	return <>
		<Button
			icon={DeleteIcon}
			label={'Verwijderen'}
			priority={ButtonPriority.SecondaryDanger}
			onClick={handleButtonClick}
			type={ButtonType.Button}
		/>
		<DeleteClientDialog
			open={open}
			client={client}
			onCancel={handleCancel}
			onDeleted={handleDeleted}
		/>
	</>;
};