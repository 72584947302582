import * as React from 'react';

import { Id } from '@docsys/common-types';
import { CoinList, CoinListLinkItem, CoinListType, CoinSize } from '@docsys/controls';
import { TenantIcon } from '@docsys/icons';

interface Tenant {
    tenantId: Id;
    displayName: string;
}

interface TenantListProps {
    tenants: Tenant[];
}

interface TenantListItem extends CoinListLinkItem {
	// nothing
}

export const TenantList: React.VFC<TenantListProps> = (props) => {
	const {
		tenants,
	} = props;

	const items: TenantListItem[] = tenants.map(t => ({
		id: t.tenantId,
		icon: TenantIcon,
		title: t.displayName,
		subtitle: t.tenantId,
		to: `/tenants/tenant/${t.tenantId}`,
	}));

	return <CoinList coinSize={CoinSize.ListDouble} items={items} type={CoinListType.Links} />;
};
