import { Id } from '@docsys/common-types';

import { apiEndpoint } from '../app/api-endpoint';
import { ControllerBase, TokenProvider } from './base-controller';

interface GetAllTenantsResponse {
	tenants: TenantsResponse[];
}

export interface TenantsResponse {
	tenantId: Id;
	displayName: string;
	editable: boolean;
}

export interface DetailedTenantResponse {
	tenantId: Id;
	displayName: string;
	hostnames: string[];
	clientIds: Id[];
	externalAuthentication: ExternalAuthenticationResponse;
	connectionString: string;
	deletable: boolean;
	editable: boolean;
}

interface InternalMetadataInformation {
	wsFedInternalMetaDataUrl: string;
	saml2InternalMetaDataUrl: string;
}

export type ExternalAuthenticationType = 'oidc' | 'saml2' | 'wsfed';

type ExternalAuthenticationResponse = (OidcExternalAuthenticationResponse | Saml2ExternalAuthenticationResponse | WsfedExternalAuthenticationResponse) & {
	type: ExternalAuthenticationType;
	internalMetadataInformation: InternalMetadataInformation;
};

interface OidcExternalAuthenticationResponse {
	type: 'oidc';
	externalClientId: string;
	externalMetadataUrl: string;
}

export type Saml2NameIdType = 'unspecified' | 'emailAddress' | 'entity' | 'persistent';

interface Saml2ExternalAuthenticationResponse {
	type: 'saml2';
	externalMetadataUrl: string;
	logInRealm: string;
	nameIdType: Saml2NameIdType;
}

interface WsfedExternalAuthenticationResponse {
	type: 'wsfed'
	externalMetadataUrl: string;
}

export type ExternalAuthenticationRequest = OidcExternalAuthenticationRequest | Saml2ExternalAuthenticationRequest | WsfedExternalAuthenticationRequest;

interface OidcExternalAuthenticationRequest {
	type: 'oidc';
	externalClientId: string;
	externalMetaDataUrl: string;
	clientSecret: string | null;
}

interface Saml2ExternalAuthenticationRequest {
	type: 'saml2';
	externalMetaDataUrl: string;
	logInRealm: string;
	nameIdType: Saml2NameIdType;
}

interface WsfedExternalAuthenticationRequest {
	type: 'wsfed';
	externalMetaDataUrl: string;
}

export interface CreateTenantRequest {
	displayName: string;
}

interface CreateTenantResponse {
	tenantId: Id;
}

export interface UpdateTenantRequest {
	displayName: string;
	hostnames: string[];
	clientIds: Id[];
	externalAuthentication: ExternalAuthenticationRequest;
}

interface UpdateTenantResponse {

}

export class TenantsEndpoint extends ControllerBase {
	constructor(tokenProvider: TokenProvider) {
		super(apiEndpoint, tokenProvider);
	}

	async getAllTenants() {
		let result = await this.get<GetAllTenantsResponse>('v1/tenants');

		if (result.ok) {
			result.tenants.sort((a, b) => a.displayName.localeCompare(b.displayName, 'nl', { ignorePunctuation: true, sensitivity: 'base', usage: 'sort' }));
		}

		return result;
	}

	getTenantDetails(tenantId: Id) {
		return this.get<DetailedTenantResponse>(`v1/tenants/${tenantId}`);
	}

	createTenant(request: CreateTenantRequest) {
		return this.post<CreateTenantRequest, CreateTenantResponse>('v1/tenants/', request);
	}

	updateTenant(tenantId: Id, request: UpdateTenantRequest) {
		return this.patch<UpdateTenantRequest, UpdateTenantResponse>(`v1/tenants/${tenantId}`, request);
	}

	deleteTenant(tenantId: Id) {
		return this.delete<never>(`v1/tenants/${tenantId}`);
	}
}
