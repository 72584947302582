import * as React from 'react';

import { ButtonStack, Content, H1, H2, LinkButton, LinkButtonPriority, Panel } from '@docsys/controls';
import { AddIcon } from '@docsys/icons';

import './index.scss';

export const Home: React.VFC = () => {
	return <Content>
		<H1 first>Beheer</H1>

		<div className='panel-list'>
			<Panel className='panel-list__panel'>
				<H2 first>Tenants</H2>
				<ButtonStack>
					<LinkButton label="Overzicht van alle tenants" to="/tenants" />
					<LinkButton icon={AddIcon} priority={LinkButtonPriority.Tertiary} label="Tenant toevoegen" to='/tenants/add' />
				</ButtonStack>
			</Panel>
			<Panel className='panel-list__panel'>
				<H2 first>Clients</H2>
				<ButtonStack>
					<LinkButton label="Overzicht van alle clients" to="/clients" />
					<LinkButton icon={AddIcon} priority={LinkButtonPriority.Tertiary} label="Frontend client toevoegen" to='/clients/add/frontend' />
					<LinkButton icon={AddIcon} priority={LinkButtonPriority.Tertiary} label="Backend client toevoegen" to='/clients/add/backend' />
				</ButtonStack>
			</Panel>
			<Panel className='panel-list__panel'>
				<H2 first>Scopes</H2>
				<ButtonStack>
					<LinkButton label="Overzicht van alle scopes" to="/scopes" />
					<LinkButton icon={AddIcon} priority={LinkButtonPriority.Tertiary} label="Scope toevoegen" to='/scopes/add' />
				</ButtonStack>
			</Panel>
			<Panel className='panel-list__panel'>
				<H2 first>Impersonation</H2>
				<ButtonStack>
					<LinkButton label="Boots een gebruiker van een andere tenant na" to="/impersonation" />
				</ButtonStack>
			</Panel>
		</div>
	</Content>;
};
