import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AccessTokenContext } from '@docsys/auth';
import { Id } from '@docsys/common-types';
import { ButtonSet, LinkButton, LinkButtonPriority, Loading, LoadingVariant } from '@docsys/controls';
import { PreviousPageIcon } from '@docsys/icons';
import {
	DetailedClientResponse,
	ErrorResult,
	ClientsEndpoint,
	ScopesEndpoint,
	TenantsResponse,
	TenantsEndpoint,
} from '~/controllers';

import { ErrorPage } from '../../error';
import { ClientsPage } from '../page';
import { UpdateClientDetails } from './form';

export const ClientDetails: React.VFC = () => {
	const { id } = useParams();

	const tokenProvider = React.useContext(AccessTokenContext);

	const [details, setDetails] = React.useState<DetailedClientResponse | null>(null);
	const [scopes, setScopes] = React.useState<string[] | null>(null);
	const [tenants, setTenants] = React.useState<TenantsResponse[] | null>(null);
	const [error, setError] = React.useState<ErrorResult | null>(null);
	const [updates, setUpdates] = React.useState<number>(0);

	React.useEffect(() => {
		const asyncEffect = async () => {
			const clientsEndpoint = new ClientsEndpoint(tokenProvider);
			const scopesEndpoint = new ScopesEndpoint(tokenProvider);
			const tenantsEndpoint = new TenantsEndpoint(tokenProvider);
			const detailsResponse = await clientsEndpoint.getClientDetails(id as Id);

			if (!detailsResponse.ok) {
				setError(detailsResponse);
				return;
			}

			setDetails(detailsResponse);

			const scopesResponse = await scopesEndpoint.getAllScopes();

			if (!scopesResponse.ok) {
				setError(scopesResponse);
				return;
			}

			setScopes(scopesResponse.scopes.map(s => s.name));

			const tenantsResponse = await tenantsEndpoint.getAllTenants();

			if (!tenantsResponse.ok) {
				setError(tenantsResponse);
				return;
			}

			setTenants(tenantsResponse.tenants);
		};

		asyncEffect();
	}, [updates, id, tokenProvider]);

	if (error) {
		return <ErrorPage error={error} />
	}

	return <ClientsPage>
		<ButtonSet>
			<LinkButton
				icon={PreviousPageIcon}
				label={'Terug naar overzicht'}
				priority={LinkButtonPriority.Tertiary}
				to={'/clients'}
			/>
		</ButtonSet>
		<hr/>
		{ (details && scopes && tenants)
			? <UpdateClientDetails
				details={details}
				scopes={scopes}
				tenants={tenants}
				onUpdate={() => { setUpdates(updates + 1); }}
			/>
			: <Loading label={'Label...'} variant={LoadingVariant.Medium} />
		}
	</ClientsPage>;
};
