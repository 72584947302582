import * as React from 'react';

import {
	Alert,
	AlertType,
	Button,
	ButtonPriority,
	ButtonType,
	Dialog,
	DialogType,
	Form,
	FormTextField,
} from '@docsys/controls';
import { usePrompt } from '@docsys/hooks';
import { CancelIcon, DeleteIcon } from '@docsys/icons';

import { ClientResponse } from '~/controllers';
import { useDeleteClientForm } from './use-delete-client-form';

interface DeleteClientProps {
	client: ClientResponse,
	open: boolean;
	onCancel: () => void,
	onDeleted: (displayName: string) => void,
}

export const DeleteClientDialog: React.VFC<DeleteClientProps> = (props) => {
	const {
		client,
		open,
		onCancel,
		onDeleted,
	} = props;

	const handleCancel = () => {
		onCancel();
	};

	const form = useDeleteClientForm({
		client,
		onDeleted: onDeleted,
	});

	usePrompt('Weet je zeker dat je deze pagina wil verlaten?\nGemaakte wijzigingen worden mogelijk niet opgeslagen.', form.busy);

	return <Dialog
		header={{
			closeButton: true,
			closeButtonLabel: 'Annuleren',
		}}
		footer={{
			buttons: <>
				<Button
					busy={form.busy}
					disabled={form.invalid}
					icon={DeleteIcon}
					label={'Client permanent verwijderen'}
					onClick={form.submit}
					priority={ButtonPriority.PrimaryDanger}
					type={ButtonType.Button}
				/>
				<Button
					disabled={form.busy}
					icon={CancelIcon}
					label={'Annuleren'}
					onClick={handleCancel}
					priority={ButtonPriority.Secondary}
					type={ButtonType.Button}
				/>
			</>,
		}}
		open={open}
		title={'Client verwijderen'}
		type={DialogType.Danger}
		onClose={handleCancel}
	>
		<span style={{ userSelect: 'none' }}>
			<Alert
				type={AlertType.Danger}
				text={['Let op!', 'Je staat op het punt om een client (' + client.displayName + ') permanent te verwijderen.', 'Deze actie kan niet ongedaan gemaakt worden.']}
				layout={{ marginBlockEnd: 1 }}
			/>
		</span>

		<Form onSubmit={form.submit}>
			<FormTextField
				label={'Weergavenaam:'}
				form={form}
				entry={'displayName'}
			/>
		</Form>
	</Dialog>;
};