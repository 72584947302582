import * as React from 'react';

import { Content, H1 } from '@docsys/controls';

export const TenantsPage: React.FC = (props) => {
	const {
		children,
	} = props;

	return <Content>
		<H1 first>Beheer - impersonation</H1>
		{children}
	</Content>;
};
