import * as React from 'react';

import {
	Alert,
	AlertType,
	Button,
	ButtonPriority,
	ButtonType,
	Dialog,
	DialogType,
	Form,
	FormTextField,
} from '@docsys/controls';
import { usePrompt } from '@docsys/hooks';
import { CancelIcon, DeleteIcon } from '@docsys/icons';

import { ClientResponse, ScopeResponse } from '~/controllers';
import { useDeleteScopeForm } from './use-delete-scope-form';

interface DeleteScopeProps {
	scope: ScopeResponse,
	clients: ClientResponse[],
	open: boolean;
	onCancel: () => void,
	onDeleted: (displayName: string) => void,
}

export const DeleteScopeDialog: React.VFC<DeleteScopeProps> = (props) => {
	const {
		scope,
		clients,
		open,
		onCancel,
		onDeleted,
	} = props;

	const handleCancel = () => {
		onCancel();
	};

	const form = useDeleteScopeForm({
		scope,
		onDeleted: onDeleted,
	});

	usePrompt('Weet je zeker dat je deze pagina wil verlaten?\nGemaakte wijzigingen worden mogelijk niet opgeslagen.', form.busy);

	return <Dialog
		header={{
			closeButton: true,
			closeButtonLabel: 'Annuleren',
		}}
		footer={{
			buttons: <>
				<Button
					busy={form.busy}
					disabled={form.invalid}
					icon={DeleteIcon}
					label={'Scope permanent verwijderen'}
					onClick={form.submit}
					priority={ButtonPriority.PrimaryDanger}
					type={ButtonType.Button}
				/>
				<Button
					disabled={form.busy}
					icon={CancelIcon}
					label={'Annuleren'}
					onClick={handleCancel}
					priority={ButtonPriority.Secondary}
					type={ButtonType.Button}
				/>
			</>,
		}}
		open={open}
		title={'Scope verwijderen'}
		type={clients.length > 1 ? DialogType.Danger : DialogType.Warning}
		onClose={handleCancel}
	>
		<span style={{ userSelect: 'none' }}>
			{ clients.length === 0
				? <Alert
					type={AlertType.Warning}
					text={['Let op!', 'Je staat op het punt om een scope (' + scope.name + ') permanent te verwijderen.', 'Deze actie kan niet ongedaan gemaakt worden.']}
					layout={{ marginBlockEnd: 1 }}
				/>
				: <Alert
					type={AlertType.Danger}
					text={[
						'Let op!',
						'Je staat op het punt om een scope (' + scope.name + ') permanent te verwijderen.',
						`${scope.name} wordt gebruikt door ${clients.length} ${clients.length === 1 ? 'client' : 'clients'}: ${clients.map(c => c.displayName).join(', ')}. De scope wordt uit deze ${clients.length === 1 ? 'client' : 'clients'} gehaald.`,
						'Deze actie kan niet ongedaan gemaakt worden.']}
					layout={{ marginBlockEnd: 1 }}
				/>
			}
		</span>

		<Form onSubmit={form.submit}>
			<FormTextField
				label={'Naam:'}
				form={form}
				entry={'name'}
			/>
		</Form>
	</Dialog>;
};
