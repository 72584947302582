import { ValidationMessageType } from '@docsys/hooks';

const scopeRegex = /^[\x21\x23-\x5B\x5D-\x7E]+$/;

export const scopeValidator = (existingScopes: string[]) => ({
	id: 'scope',
	validate(value: string) {
		if (!value || !value.trim()) {
			return {
				text: 'Naam is verplicht.',
				type: ValidationMessageType.Invalid,
			};
		}

		if (value === 'openid' || value === 'profile' || value === 'email') {
			return {
				text: `${value} is een gereserveerde naam, deze kan je niet gebruiken.`,
				type: ValidationMessageType.Invalid,
			};
		}

		if (!scopeRegex.test(value)) {
			return {
				text: 'De naam van een scope mag alleen bestaat uit letters, cijfers en simpele leestekens. \\ en " zijn niet toegestaan.',
				type: ValidationMessageType.Invalid,
			};
		}

		if (value.length > 1000) {
			return {
				text: 'Naam is te lang (' + value.length + ' tekens). Hou de naam korter dan 1000 tekens.',
				type: ValidationMessageType.Invalid,
			};
		}

		if (existingScopes.includes(value)) {
			return {
				text: 'Er bestaat al een scope met deze naam.',
				type: ValidationMessageType.Invalid,
			};
		}

		const cleanExistingScopes = existingScopes.map(s => s.toLowerCase().replace(/[^a-z0-9]/, ''));
		const cleanValue = value.toLowerCase().replace(/[^a-z0-9]/, '');
		const index = cleanExistingScopes.indexOf(cleanValue);

		if (index >= 0) {
			return {
				text: `Er bestaat al een scope genaamd "${existingScopes[index]}". Deze naam is mogelijk verwarrend.`,
				type: ValidationMessageType.Warning,
			};
		}

		if (value.toLowerCase() !== value) {
			return {
				text: 'De naam is hoofdlettergevoelig, gebruik bij voorkeur alleen kleine letters.',
				type: ValidationMessageType.Warning,
			};
		}

		if (value.length > 50) {
			return {
				text: 'Naam is wel erg lang (' + value.length + ' tekens). Hou de weergavenaam kort.',
				type: ValidationMessageType.Warning,
			};
		}

		return {
			text: 'Naam is geldig en nog niet in gebruik.',
			type: ValidationMessageType.Valid,
		};
	},
});
