import * as React from 'react';

import { Button, ButtonPriority, ButtonType } from '@docsys/controls';
import { AddIcon } from '@docsys/icons';

import { ScopePropertyDialog } from './scope-property-dialog';

interface AddPropertyButtonProps {
	onAdd: (type: string, value: string) => void;
}

export const AddPropertyButton: React.VFC<AddPropertyButtonProps> = (props) => {
	const {
		onAdd,
	} = props;

	const [open, setOpen] = React.useState<boolean>(false);

	const handleButtonClick = () => {
		setOpen(true);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	const handleDone = (_: number, type: string, value: string) => {
		onAdd(type, value);
		setOpen(false);
	};

	return <>
		<Button
			icon={AddIcon}
			label={'Eigenschap toevoegen…'}
			onClick={handleButtonClick}
			priority={ButtonPriority.Tertiary}
			type={ButtonType.Button}
		/>
		<ScopePropertyDialog
			add
			open={open}
			onCancel={handleCancel}
			index={-1}
			type={''}
			value={''}
			onDone={handleDone}
		/>
	</>;
};