import * as React from 'react';

import { EndpointsContext } from '@docsys/auth';
import { EndpointsContextData } from '@docsys/auth/dist/context/endpoints/endpoints-context.types';
import { Loading, LoadingVariant } from '@docsys/controls';
import { Theme } from '@docsys/theme';

export const AppTheme: React.FC = (props) => {
	const {
		children,
	} = props;

	const endpoints = React.useContext<EndpointsContextData>(EndpointsContext);

	if (!endpoints.initialized) {
		return <Loading
			label={'Laden...'}
			variant={LoadingVariant.Large}
		/>;
	}

	return <>
		<Theme darkMode={'light'} cdnUrl={endpoints.endpoints.cdn} style={'blauw'} />
		{ children }
	</>;
};
