import *  as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NotFoundErrorPage } from '../error/not-found';

import { AddScope } from './add';
import { ScopeDetails } from './details';
import { ScopeOverview } from './overview';

export const ScopeRoutes: React.VFC = () => {
	return <Routes>
		<Route index element={<ScopeOverview/>} />
		<Route path='add' element={<AddScope/>} />
		<Route path='scope/:name' element={<ScopeDetails />} />
		<Route path="*" element={<NotFoundErrorPage/>} />
	</Routes>;
};
