import * as React from 'react';

import {
	Alert,
	AlertType,
	Button,
	ButtonLayout,
	ButtonPriority,
	ButtonType,
	Dialog,
	Form,
	ToastDuration,
	UnmanagedPasswordField,
	useToast,
} from '@docsys/controls';
import { CopyIcon } from '@docsys/icons';

export interface SecretsDialogProps {
	open: boolean;
	secret?: string;
	displayName?: string;
	onDone?: () => void;
}

export const SecretsDialog: React.VFC<SecretsDialogProps> = (props) => {
	const {
		displayName = '',
		onDone = () => {},
		open,
		secret = '',
	} = props;

	const { showToast } = useToast();

	const handleCopyClick = () => {
		navigator.clipboard.writeText(secret);
		showToast({
			text: 'Secret gekopieerd naar klembord.',
			duration: ToastDuration.Short,
			id: 'copy',
			icon: CopyIcon,
		});
	};

	return <Dialog
		header={{
			closeButton: false,
			closeButtonLabel: 'Dialoog sluiten',
		}}
		footer={{
			buttons: <>
				<Button
					label={'Klaar'}
					type={ButtonType.Button}
					onClick={() => {
						onDone()
					}}
				/>
			</>,
		}}
		open={open}
		title={'Secret voor client ' + displayName}
	>
		<Form readonly>
			<Alert
				type={AlertType.Info}
				text={[
					`Hieronder staat de gegenereerde secret voor de nieuwe client "${displayName}".`,
					'Bewaar deze zorgvuldig, dit is de enige keer dat je deze tezien krijgt.',
				]}
			/>
			<UnmanagedPasswordField
				buttonsAfter={
					<Button
						icon={CopyIcon}
						label={'Kopiëeren'}
						layout={ButtonLayout.IconOnly}
						onClick={handleCopyClick}
						priority={ButtonPriority.Tertiary}
						type={ButtonType.Button}
					/>
				}
				hideButtonLabel={'Secret verbergen'}
				showButtonLabel={'Secret tonen'}
				label={'Secret:'}
				readonly
				value={secret}
			/>
		</Form>
	</Dialog>;
};
