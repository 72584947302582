import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import {
	Button,
	ButtonPriority,
	ButtonSet,
	ButtonType,
	CheckboxListOption,
	Form,
	FormCheckboxList,
	FormSeparator,
	FormTextField,
	H2,
	ToastDuration,
	useToast,
} from '@docsys/controls';
import { useForm, usePrompt } from '@docsys/hooks';
import { CancelIcon, EditIcon, OkIcon } from '@docsys/icons';

import { CopyFormTextField, displayNameValidator } from '~/components';
import { ClientsEndpoint, UpdateClientRequest } from '~/controllers';

import { DeleteClientButton } from '../delete/delete-client-button';

import { UpdateClientDetailsProps, UpdateClientFormData } from './form.types';
import { getGrantTypes, grantTypeValidator } from './grant-types';
import { redirectUrlTemplateDefault, redirectUrlTemplateValidator } from './redirect-url-template';
import { ResetSecretButton } from './reset-secret-button';
import { frontendScopesValidator, getScopes } from './scopes';

export const UpdateClientDetails: React.VFC<UpdateClientDetailsProps> = (props) => {
	const {
		details,
		scopes,
		tenants,
		onUpdate,
	} = props;

	const clientType = details.type;

	const { showToast } = useToast();

	const tokenProvider = React.useContext(AccessTokenContext);
	const clientsEndpoint = new ClientsEndpoint(tokenProvider);

	const [edit, setEdit] = React.useState<boolean>(false);

	const grantTypeOptions = getGrantTypes(details);
	const scopeOptions = getScopes(details, scopes);
	const tenantOptions: CheckboxListOption<string>[] = tenants.map(t => ({
		label: t.displayName,
		value: t.tenantId,
		disabled: !t.editable,
	}));

	const form = useForm<UpdateClientFormData>({
		initialData: {
			id: details.id,
			displayName: details.displayName,
			grantType: details.grantTypes || [],
			scopes: details.scopes || [],
			tenants: details.tenants || [],
			redirectUrlTemplate: details.redirectUrlTemplate || '',
		},
		validations: {
			displayName: [displayNameValidator],
			grantType: (clientType === 'backend' ? [grantTypeValidator] : []),
			scopes: (clientType === 'frontend' ? [frontendScopesValidator] : []),
			redirectUrlTemplate: (clientType === 'frontend' ? [redirectUrlTemplateValidator] : []),
		},
		submit: async (data) => {
			const request: UpdateClientRequest = {
				displayName: data.displayName,
				grantTypes: clientType === 'backend' ? data.grantType : undefined,
				scopes: data.scopes,
				tenants: data.tenants,
				redirectUrlTemplate: clientType === 'frontend' ? (data.redirectUrlTemplate || '') : undefined,
			};

			let result = await clientsEndpoint.updateClient(data.id, request);

			if (result.ok) {
				setEdit(false);
				showToast({
					text: 'Wijzigingen opgeslagen.',
					duration: ToastDuration.Short,
				});
				onUpdate();
			}

			return result;
		},
	});

	usePrompt('Weet je zeker dat je deze pagina wil verlaten?\nGemaakte wijzigingen worden mogelijk niet opgeslagen.', form.busy);
	usePrompt('Weet je zeker dat je deze pagina wil verlaten?\nJe hebt wijzigingen gemaakt die je niet hebt opgeslagen.', !form.busy && edit && form.dirty);

	return <Form readonly={!edit} onSubmit={form.submit}>
		{ !edit && <ButtonSet>
			<Button
				icon={EditIcon}
				label={'Bewerken'}
				onClick={() => setEdit(true)}
				type={ButtonType.Button}
				priority={ButtonPriority.Secondary}
			/>
			{ clientType === 'backend' && <ResetSecretButton
				client={details}
			/> }
			<DeleteClientButton client={details} />
		</ButtonSet> }

		<H2 first layout={{ limitLines: 1 }}>{(form.getEntry<string>('displayName').value[0]) || (form.getEntry<string>('id').value[0])}</H2>

		<CopyFormTextField
			copyLabel={'ID kopiëeren'}
			toastMessage={'ID gekopiëerd naar klembord.'}
			label='ID:'
			entry='id'
			readonly
			form={form}
		/>
		<FormTextField
			label='Weergavenaam:'
			entry='displayName'
			form={form}
		/>

		{(clientType === 'backend') && <>
			<FormCheckboxList
				label={'Grant types:'}
				options={grantTypeOptions}
				placeholder={'Geen grant types gedefiniëerd.'}
				entry={'grantType'}
				form={form}
			/>
		</> }

		{(clientType === 'frontend') && <FormTextField
			label={'Hostname-sjabloon:'}
			entry={'redirectUrlTemplate'}
			placeholder={redirectUrlTemplateDefault}
			form={form}
		/> }

		<FormCheckboxList
			label={'Scopes:'}
			options={scopeOptions}
			placeholder={'Geen scopes gedefiniëerd.'}
			entry={'scopes'}
			form={form}
		/>

		<FormCheckboxList
			label={'Tenants:'}
			options={tenantOptions}
			placeholder={'Geen tenants gedefiniëerd.'}
			entry={'tenants'}
			form={form}
		/>

		{ edit && <>
			<FormSeparator />

			<ButtonSet>
				<Button
					busy={form.busy}
					disabled={form.invalid}
					icon={OkIcon}
					label={'Opslaan'}
					priority={ButtonPriority.Primary}
					type={ButtonType.Submit}
				/>
				<Button
					icon={CancelIcon}
					label={'Annuleren'}
					type={ButtonType.Button}
					onClick={() => {
						form.reset();
						setEdit(false);
					}}
				/>
			</ButtonSet>
		</> }
	</Form>;
};
