import * as React from 'react';
import { useParams } from 'react-router-dom';

import { AccessTokenContext } from '@docsys/auth';
import { Id } from '@docsys/common-types';
import { ButtonSet, LinkButton, LinkButtonPriority, Loading, LoadingVariant } from '@docsys/controls';
import { PreviousPageIcon } from '@docsys/icons';
import { ClientResponse, ClientsEndpoint, DetailedScopeResponse, ErrorResult, ScopesEndpoint } from '~/controllers';

import { ErrorPage } from '../../error';
import { ScopesPage } from '../page';
import { UpdateScopeDetails } from './form';

export const ScopeDetails: React.VFC = () => {
	const {
		name,
	} = useParams();

	const tokenProvider = React.useContext(AccessTokenContext);

	const [updates, setUpdates] = React.useState<number>(0);
	const [clients, setClients] = React.useState<ClientResponse[] | null>(null);
	const [details, setDetails] = React.useState<DetailedScopeResponse | null>(null);
	const [error, setError] = React.useState<ErrorResult | null>(null);

	React.useEffect(() => {
		const asyncEffect = async () => {
			const scopesEndpoint = new ScopesEndpoint(tokenProvider);
			const scopeResponse = await scopesEndpoint.getScopeDetails(name as Id);

			if (!scopeResponse.ok) {
				setError(scopeResponse);
				return;
			}

			setDetails(scopeResponse);

			const clientsEndpoint = new ClientsEndpoint(tokenProvider);
			const clientsResponse = await clientsEndpoint.getAllClients();

			if (!clientsResponse.ok) {
				setError(clientsResponse);
				return;
			}

			setClients(clientsResponse.clients);
		};

		asyncEffect();
	}, [name, updates, tokenProvider]);

	if (error) {
		return <ErrorPage error={error} />
	}

	return <ScopesPage>
		<ButtonSet>
			<LinkButton
				icon={PreviousPageIcon}
				label={'Terug naar overzicht'}
				priority={LinkButtonPriority.Tertiary}
				to={'/scopes'}
			/>
		</ButtonSet>
		<hr/>
		{ clients && details
			? <UpdateScopeDetails clients={clients} details={details} onUpdated={() => setUpdates(updates + 1)} />
			: <Loading label={'Laden...'} variant={LoadingVariant.Medium} />
		}
	</ScopesPage>;
};
