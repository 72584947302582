import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import {
	Alert,
	AlertType,
	Button,
	ButtonLayout,
	ButtonPriority,
	ButtonType,
	Dialog,
	Form,
	Loading,
	LoadingVariant,
	ToastDuration,
	UnmanagedPasswordField,
	useToast,
} from '@docsys/controls';
import { CancelIcon, CopyIcon, RefreshIcon } from '@docsys/icons';

import { ClientResponse, ClientsEndpoint } from '~/controllers';

interface ResetSecretDialogProps {
	open: boolean;
	client: ClientResponse,
	onClose: () => void;
}

export const ResetSecretDialog: React.VFC<ResetSecretDialogProps> = (props) => {
	const {
		open,
		client,
		onClose,
	} = props;

	const { showToast } = useToast();

	const tokenProvider = React.useContext(AccessTokenContext);
	const clientsEndpoint = new ClientsEndpoint(tokenProvider);

	const [secret, setSecret] = React.useState<string | null>(null);
	const [busy, setBusy] = React.useState<boolean>(false);

	const handleClose = () => {
		if (busy) {
			return;
		}

		onClose();

		window.setTimeout(() => {
			setSecret(null);
		}, 500);
	};

	const handleGenerate = async () => {
		setBusy(true);

		const result = await clientsEndpoint.resetSecret(client.id);

		setBusy(false);

		if (result.ok) {
			setSecret(result.secret);

			showToast({
				text: 'Nieuwe secret gegenereerd.',
			});
		}
	};

	const handleCopyClick = () => {
		navigator.clipboard.writeText(secret || '');
		showToast({
			text: 'Secret gekopieerd naar klembord.',
			duration: ToastDuration.Short,
			id: 'copy',
			icon: CopyIcon,
		});
	};

	return <Dialog
		header={{
			closeButton: true,
			closeButtonLabel: 'Dialoog sluiten',
		}}
		footer={{
			buttons: <>
				{ (!secret) && <Button
					busy={busy}
					icon={RefreshIcon}
					label={'Secret opnieuw instellen'}
					onClick={handleGenerate}
					priority={ButtonPriority.Primary}
					type={ButtonType.Button}
				/> }
				<Button
					busy={busy}
					icon={CancelIcon}
					label={(!secret) ? 'Annuleren' : 'Dialoog sluiten'}
					onClick={handleClose}
					priority={ButtonPriority.Secondary}
					type={ButtonType.Button}
				/>
			</>,
		}}
		open={open}
		title={'Secret opnieuw instellen'}
		onClose={handleClose}
	>
		{ busy && <Loading
			label={'Laden...'}
			variant={LoadingVariant.Small}
		/> }
		{ (!secret && !busy) && <>
			<Alert
				type={AlertType.Warning}
				text={[
					'Let op!',
					'Als je door gaat wordt een nieuwe client secret voor de client "' + client.displayName + '" gegenereerd.',
					'Toepassingen die gebruik maken van deze client kunnen hierdoor toegang verliezen.',
				]}
			/>
		</> }
		{ (!!secret && !busy) && <Form readonly>
			<Alert
				type={AlertType.Info}
				text={[
					`Hieronder staat de nieuwe gegenereerde secret voor de nieuwe client "${client.displayName}".`,
					'Bewaar deze zorgvuldig, dit is de enige keer dat je deze tezien krijgt.',
				]}
			/>
			<UnmanagedPasswordField
				buttonsAfter={
					<Button
						icon={CopyIcon}
						label={'Kopiëeren'}
						layout={ButtonLayout.IconOnly}
						onClick={handleCopyClick}
						priority={ButtonPriority.Tertiary}
						type={ButtonType.Button}
					/>
				}
				hideButtonLabel={'Secret verbergen'}
				showButtonLabel={'Secret tonen'}
				label={'Secret:'}
				readonly
				value={secret}
			/>
		</Form>}
	</Dialog>;
};
