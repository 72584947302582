import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import { Alert, AlertType, LinkButton, ButtonPriority, ButtonSet, Loading, LoadingVariant } from '@docsys/controls';
import { AddIcon, PreviousPageIcon } from '@docsys/icons';
import { ScopesEndpoint, ScopeResponse, ErrorResult } from '~/controllers';

import { ErrorPage } from '../../error';
import { ScopesPage } from '../page';
import { ScopeList } from '../scope-list';

export const ScopeOverview: React.VFC = () => {
	const tokenProvider = React.useContext(AccessTokenContext);

	const [scopes, setScopes] = React.useState<ScopeResponse[] | null>(null);
	const [error, setError] = React.useState<ErrorResult | null>(null);

	React.useEffect(() => {
		const asyncEffect = async () => {
			const scopesEndpoint = new ScopesEndpoint(tokenProvider);

			try {
				const response = await scopesEndpoint.getAllScopes();
				if (response.ok) {
					setScopes(response.scopes);
				}
				else {
					setError(response);
				}
			}
			catch (e) {
				setError(e as ErrorResult);
			}
		}

		asyncEffect();

	}, [tokenProvider]);

	if (error) {
		return <ErrorPage error={error} />
	}

	return <ScopesPage>
		<ButtonSet>
			<LinkButton to={'/'} icon={PreviousPageIcon} label={'Naar Index'} priority={ButtonPriority.Tertiary} />
		</ButtonSet>

		<hr />

		{ !scopes && <Loading label={'Laden...'} variant={LoadingVariant.Medium} /> }

		{ !!scopes && <>
			<ButtonSet layout={{ marginBlockStart: 1, marginBlockEnd: 1 }} >
				<LinkButton to={'/scopes/add'} icon={AddIcon} label={'Scope toevoegen'} priority={ButtonPriority.Primary} />
			</ButtonSet>
			{ scopes.length
				? <ScopeList scopes={scopes} />
				: <Alert type={AlertType.Info} text='Er zijn nog geen scopes.' />
			}
		</> }
	</ScopesPage>;
};
