import { AccessTokenContextData } from '@docsys/auth';
import { SubmitResponseOk, SubmitResponseValidationFailed } from '@docsys/hooks/dist/use-form/use-form.types';


export type TokenProvider = AccessTokenContextData;

type RequestBody<T extends Record<string, any> | never> = T extends never ? undefined : T;

export type Result<I extends Record<string, any> | never, O extends Record<string, any> | never> = OkResult<O> | ErrorResult<I>;

export type OkResult<T extends Record<string, any> | never> = T extends never ? SubmitResponseOk : SubmitResponseOk & T;

export type ErrorResult<T extends Record<string, any> | never = never> = SubmitResponseValidationFailed<T> & { message?: string, clientMessage?: string };

export abstract class ControllerBase {
	#location: string;
	#tokenProvider: TokenProvider;

	/**
	 * Constructor.
	 * @param location - The location of all endpoints.
	 */
	constructor(location: string, tokenProvider: TokenProvider) {
		this.#location = location;
		this.#tokenProvider = tokenProvider;
	}

	async #request<I, O>(method: string, uri: string, body?: RequestBody<I>): Promise<Result<I, O>> {
		const headers = new Headers();
		headers.append('Accept', 'application/json');
		headers.append('Content-Type', 'application/json');

		const bearerToken = await this.#tokenProvider.getToken();

		if (bearerToken) {
			headers.append('Authorization', 'Bearer ' + bearerToken);
		}

		const response = await window.fetch(this.#location + uri, {
			method,
			headers,
			body: body ? JSON.stringify(body) : undefined,
		});

		if (!response.ok) {
			switch (response.status) {
				case 401: {
					const renewed = await this.#tokenProvider.renew();

					if (!renewed) {
						return {
							ok: false,
							message: 'Geen toegang',
						} as ErrorResult<I>;
					}

					return this.#request(method, uri, body);
				}
			}

			let responseData: Omit<ErrorResult<I>, 'ok'>;

			try {
				responseData = await response.json();
			}
			catch {
				throw new Error('Invalid JSON');
			}

			return Object.assign({
				ok: false,
			}, responseData) as ErrorResult<I>;
		}

		switch (response.status) {
			case 204:
				return { ok: true } as OkResult<never>;

			case 401: {
				const renewed = await this.#tokenProvider.renew();

				if (!renewed) {
					return {
						ok: false,
						message: 'Geen toegang',
					} as ErrorResult<I>;
				}

				return this.#request(method, uri, body);
			}
		}

		let responseData: O;

		try {
			responseData = await response.json();
		}
		catch {
			throw new Error('Invalid JSON');
		}

		let r = Object.assign({
			ok: true,
		}, responseData) as OkResult<O>;

		return r;
	}

	get<O>(uri: string) {
		return this.#request<never, O>('GET', uri);
	}

	delete<O>(uri: string) {
		return this.#request<never, O>('DELETE', uri);
	}

	patch<I, O>(uri: string, body: RequestBody<I>) {
		return this.#request<I, O>('PATCH', uri, body);
	}

	post<I, O>(uri: string, body: RequestBody<I>) {
		return this.#request<I, O>('POST', uri, body);
	}

	mock<O extends Record<string, any>>(data: O) {
		const d = Object.assign<SubmitResponseOk, O>({ ok: true }, data);

		return new Promise(resolve => {
			setTimeout(() => resolve(d), 1000)
		});
	}
}
