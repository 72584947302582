import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import { LinkButton, ButtonPriority, ButtonSet, Loading, LoadingVariant } from '@docsys/controls';
import { PreviousPageIcon } from '@docsys/icons';
import { TenantsEndpoint, TenantsResponse, ErrorResult } from '~/controllers';

import { ErrorPage } from '../error';
import { TenantsPage } from './page';
import { TenantList } from './tenant-list';

export const Impersonation: React.VFC = () => {
	const tokenProvider = React.useContext(AccessTokenContext);

	const [tenants, setTenants] = React.useState<TenantsResponse[] | null>(null);
	const [error, setError] = React.useState<ErrorResult | null>(null);

	React.useEffect(() => {
		const asyncEffect = async () => {
			const tenantsEndpoint = new TenantsEndpoint(tokenProvider);

			try {
				const response = await tenantsEndpoint.getAllTenants();

				if (response.ok) {
					setTenants(response.tenants);
				}
				else {
					setError(response);
				}
			}
			catch (e) {
				setError(e as ErrorResult);
			}
		};

		asyncEffect();
	}, [tokenProvider]);

	if (error) {
		return <ErrorPage error={error} />
	}

	return <TenantsPage>
		<ButtonSet>
			<LinkButton to={'/'} icon={PreviousPageIcon} label={'Naar Index'} priority={ButtonPriority.Tertiary} />
		</ButtonSet>

		<hr />

		{ !tenants && <Loading label={'Laden...'} variant={LoadingVariant.Medium} /> }

		{ !!tenants && <TenantList tenants={tenants} /> }
	</TenantsPage>;
};
