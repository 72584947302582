import * as React from 'react';

import {
	Button,
	ButtonPriority,
	ButtonType,
	Dialog,
	Form,
	FormSeparator,
	FormTextArea,
	FormTextField,
} from '@docsys/controls';
import { CancelIcon, OkIcon } from '@docsys/icons';
import { UpdateScopePropertyDialogProps } from './form.types';
import { useScopePropertyForm } from './use-scope-property-form';

export const ScopePropertyDialog: React.VFC<UpdateScopePropertyDialogProps> = (props) => {
	const {
		add,
		open,
		type,
		value,
		index,
		onCancel,
		onDone,
	} = props;

	const form = useScopePropertyForm({
		type,
		value,
		onSubmit: (key, value) => {
			onDone(add ? -1 : index, key, value);
		},
	});

	const handleCancel = () => {
		onCancel();
	};

	React.useEffect(() => {
		if (!open) {
			window.setTimeout(() => {
				form.reset();
			}, 500);
		}
	}, [form, open]);

	return <Dialog
		header={{
			closeButton: true,
			closeButtonLabel: 'Annuleren',
		}}
		footer={{
			buttons: <>
				<Button
					busy={form.busy}
					disabled={form.invalid}
					icon={OkIcon}
					label={add ? 'Toevoegen' : 'Klaar'}
					onClick={form.submit}
					priority={ButtonPriority.Primary}
					type={ButtonType.Button}
				/>
				<Button
					disabled={form.busy}
					icon={CancelIcon}
					label={'Annuleren'}
					onClick={handleCancel}
					priority={ButtonPriority.Secondary}
					type={ButtonType.Button}
				/>
			</>,
		}}
		open={open}
		title={add ? 'Eigenschap toevoegen' : 'Eigenschap bewerken'}
		onClose={handleCancel}
	>
		<Form onSubmit={form.submit}>
			<FormTextField
				entry={'type'}
				form={form}
				label={'Type:'}
			/>

			<FormTextArea
				fixed-size
				entry={'value'}
				form={form}
				monospace
				label={'Waarde:'}
			/>

			<FormSeparator />
		</Form>
	</Dialog>;
};
