import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import {
	Button,
	ButtonPriority,
	ButtonSet,
	ButtonType,
	CheckboxListOption,
	Form,
	FormCheckboxList,
	FormSeparator,
	H2,
	ToastDuration,
	useToast,
} from '@docsys/controls';
import { useForm, usePrompt } from '@docsys/hooks';
import { CancelIcon, EditIcon, OkIcon } from '@docsys/icons';

import { CopyFormTextField } from '~/components';
import { ScopesEndpoint, UpdateScopeRequest } from '~/controllers';

import { DeleteScopeButton } from '../delete/delete-scope-button';
import { UpdateScopeDetailsProps, UpdateScopeFormData, UpdateScopeProperty } from './form.types';
import { ScopeProperties } from './scope-properties';

export const UpdateScopeDetails: React.VFC<UpdateScopeDetailsProps> = (props) => {
	const {
		clients,
		details,
		onUpdated,
	} = props;

	const { showToast } = useToast();

	const tokenProvider = React.useContext(AccessTokenContext);
	const scopesEndpoint = new ScopesEndpoint(tokenProvider);

	const [edit, setEdit] = React.useState<boolean>(false);

	const form = useForm<UpdateScopeFormData>({
		initialData: {
			name: details.name,
			properties: details.properties
				.map(p => ({
					type: p.type,
					value: p.value,
				})),
			clients: details.clients,
		},
		submit: async (data) => {
			const request: UpdateScopeRequest = {
				properties: data.properties
					.map(p => ({
						type: p.type,
						value: p.value,
					})),
				clients: data.clients,
			};

			let result = await scopesEndpoint.updateScope(details.name, request);

			if (result.ok) {
				setEdit(false);
				showToast({
					text: 'Wijzigingen opgeslagen.',
					duration: ToastDuration.Short,
				});

				onUpdated();
			}

			return result;
		},
	});

	const propertiesEntry = form.getEntry<UpdateScopeProperty[]>('properties').value;

	const clientsOptions: CheckboxListOption<string>[] = clients.map(c => ({
		label: c.displayName,
		value: c.id,
	}));

	usePrompt('Weet je zeker dat je deze pagina wil verlaten?\nGemaakte wijzigingen worden mogelijk niet opgeslagen.', form.busy);
	usePrompt('Weet je zeker dat je deze pagina wil verlaten?\nJe hebt wijzigingen gemaakt die je niet hebt opgeslagen.', !form.busy && edit && form.dirty);

	return <Form readonly={!edit} onSubmit={form.submit}>
		{ !edit && <ButtonSet>
			<Button
				icon={EditIcon}
				label={'Bewerken'}
				onClick={() => setEdit(true)}
				type={ButtonType.Button}
				priority={ButtonPriority.Secondary}
			/>
			<DeleteScopeButton
				clients={clients.filter(c => details.clients.includes(c.id))}
				scope={details}
			/>
		</ButtonSet> }

		<H2 first layout={{ limitLines: 1 }}>{(form.getEntry<string>('name').value[0])}</H2>

		<CopyFormTextField
			copyLabel={'Naam kopiëeren'}
			entry='name'
			form={form}
			label='Naam:'
			readonly
			toastMessage={'Naam gekopiëerd naar klembord.'}
		/>

		<ScopeProperties
			entry={propertiesEntry}
		/>

		<FormCheckboxList
			label="Clients:"
			options={clientsOptions}
			placeholder="Geen clients"
			entry={'clients'}
			form={form}
		/>

		{ edit && <>
			<FormSeparator />

			<ButtonSet>
				<Button
					busy={form.busy}
					disabled={form.invalid}
					icon={OkIcon}
					label={'Opslaan'}
					priority={ButtonPriority.Primary}
					type={ButtonType.Submit}
				/>
				<Button
					icon={CancelIcon}
					label={'Annuleren'}
					type={ButtonType.Button}
					onClick={() => {
						form.reset();
						setEdit(false);
					}}
				/>
			</ButtonSet>
		</> }
	</Form>;
};
