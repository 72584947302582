import { CheckboxListOption } from '@docsys/controls';
import { ValidationMessageType, Validator } from '@docsys/hooks';

import { DetailedClientResponse } from '~/controllers';
import { UpdateClientFormData } from './form.types';

const requiredFrontendScopes = [
	'openid',
	'profile',
	'email',
];

export function getScopes(details: DetailedClientResponse, scopes: string[]): CheckboxListOption<string>[] {
	const result: CheckboxListOption<string>[] = [];

	if (details.type === 'frontend') {
		for (const s of requiredFrontendScopes) {
			result.push({
				value: s,
				label: s,
				disabled: details.scopes.includes(s),
			});
		}
	}

	for (const s of scopes) {
		if (details.type === 'frontend' && requiredFrontendScopes.includes(s)) {
			continue;
		}

		result.push({
			value: s,
			label: s,
		});
	}

	for (const s of details.scopes) {
		if (details.type === 'frontend' && requiredFrontendScopes.includes(s)) {
			continue;
		}

		if (scopes.includes(s)) {
			continue;
		}

		result.push({
			value: s,
			label: s,
			disabled: true,
		});
	}

	return result;
}

export const frontendScopesValidator: Validator<string[], UpdateClientFormData> = {
	id: 'scopes',
	validate: (value) => {
		if (requiredFrontendScopes.some(s => !value.includes(s))) {
			return {
				text: 'De "openid", "profile" en "email" scopes zijn verplicht.',
				type: ValidationMessageType.Invalid,
			};
		}

		return null;
	},
};
