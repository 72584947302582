import * as React from 'react';

export const useDisplayNamePlaceholder = () => {
	const [displayNamePlaceholder, setDisplayNamePlaceholder] = React.useState<string>('');

	React.useEffect(() => {
		const corps = ['The Purple Dye Company', 'Khans Kantoorartiekelen', 'Ilfreann Ltd.'];
		const envs = ['Productie', 'Acceptatie'];

		const corp = corps[Math.floor(Math.random() * corps.length)];
		const env = envs[Math.floor(Math.random() * envs.length)];

		setDisplayNamePlaceholder(`${corp} – ${env}`);
	}, []);

	return displayNamePlaceholder;
};
