import * as React from 'react';

import { Id } from '@docsys/common-types';
import {
	Button,
	ButtonPriority,
	ButtonSet,
	ButtonType,
	CheckboxListOption,
	DropdownInputOption,
	Form,
	FormCheckboxList,
	FormDropdownInput,
	FormPasswordField,
	FormSeparator,
	FormTextField,
	H2,
	H3,
	LinkButtonPriority,
} from '@docsys/controls';
import { usePrompt } from '@docsys/hooks';
import { CancelIcon, EditIcon, OkIcon } from '@docsys/icons';

import {
	CopyFormTextField,
	CopyUnmanagedTextField,
	FormHost,
} from '~/components';
import {
	ExternalAuthenticationType,
	Saml2NameIdType,
} from '~/controllers';
import { useDisplayNamePlaceholder } from '~/hooks';

import { DeleteTenantButton } from '../delete/delete-tenant-button';
import { UpdateTenantDetailsProps } from './form.types';
import { useUpdateTenantForm } from './use-update-tenant-form';

const externalAuthenticationTypeOptions: DropdownInputOption<ExternalAuthenticationType>[] = [
	{
		label: 'Open ID Connect',
		value: 'oidc',
	},
	{
		label: 'WS-Federation',
		value: 'wsfed',
	},
	{
		label: 'SAML 2 - Security Assertion Markup Language 2.0',
		value: 'saml2',
	},
];

const nameIdTypeOptions: DropdownInputOption<Saml2NameIdType>[] = [
	{
		label: 'unspecified',
		value: 'unspecified',
	},
	{
		label: 'emailAddress',
		value: 'emailAddress',
	},
	{
		label: 'entity',
		value: 'entity',
	},
	{
		label: 'persistent',
		value: 'persistent',
	},
];

export const UpdateTenantDetails: React.VFC<UpdateTenantDetailsProps> = (props) => {
	const {
		details,
		clients,
	} = props;

	const internalMetadataInformation = details.externalAuthentication.internalMetadataInformation;

	const displayNamePlaceholder = useDisplayNamePlaceholder();

	const frontendClients = clients.filter(c => c.type === 'frontend');
	const backendClients = clients.filter(c => c.type === 'backend');

	const frontendClientOptions : CheckboxListOption<Id>[] = frontendClients.map(c => ({
		value: c.id,
		label: c.displayName,
	}));
	const backendClientOptions : CheckboxListOption<Id>[] = backendClients.map(c => ({
		value: c.id,
		label: c.displayName,
	}));

	const { form, edit, setEdit } = useUpdateTenantForm(details, {
		frontend: frontendClients,
		backend: backendClients,
	});

	usePrompt('Weet je zeker dat je deze pagina wil verlaten?\nJe hebt wijzigingen gemaakt die je niet hebt opgeslagen.', edit && form.dirty);
	usePrompt('Weet je zeker dat je deze pagina wil verlaten?\nGemaakte wijzigingen worden mogelijk niet opgeslagen.', form.busy);

	const authType = form.getEntry('externalAuthentication_type').value[0];

	return <Form readonly={!edit} onSubmit={form.submit}>
		{ details.editable && !edit && <ButtonSet>
			<Button
				icon={EditIcon}
				label={'Bewerken'}
				onClick={() => setEdit(true)}
				type={ButtonType.Button}
				priority={LinkButtonPriority.Secondary}
			/>
			{ details.deletable && <DeleteTenantButton tenant={details} /> }
		</ButtonSet> }

		<H2 first layout={{ limitLines: 1 }}>{(form.getEntry('displayName').value[0] as string) || (form.getEntry('tenantId').value[0] as string)}</H2>

		<CopyFormTextField
			copyLabel='Kopiëren'
			entry='tenantId'
			form={form}
			label='ID:'
			readonly
			toastMessage='ID gekopiëerd naar klembord.'
		/>
		<FormTextField
			entry='displayName'
			form={form}
			label='Weergavenaam:'
			placeholder={displayNamePlaceholder}
		/>
		<FormHost
			entry='hostnames'
			form={form}
		/>

		<CopyFormTextField
			copyLabel='Kopiëren'
			entry={'connectionString'}
			form={form}
			label={'Database connection string:'}
			readonly
			toastMessage='Database connection string gekopiëerd naar klembord.'
		/>

		<H3>Authenticatie</H3>

		<FormDropdownInput
			entry={'externalAuthentication_type'}
			form={form}
			label={'Type:'}
			options={externalAuthenticationTypeOptions}
		/>

		{ authType === 'oidc' && <>
			<FormTextField
				entry={'externalAuthentication_externalMetadataUrl'}
				form={form}
				label={'Metadata URL:'}
			/>
			<FormTextField
				entry={'externalAuthentication_externalClientId'}
				form={form}
				label={'Client ID:'}
			/>
			{ edit && <FormPasswordField
				entry={'externalAuthentication_clientSecret'}
				form={form}
				hideButtonLabel={'Verbergen'}
				label={'Client secret:'}
				messages={'Laat leeg om onaangepast te laten.'}
				showButtonLabel={'Tonen'}
			/> }
		</>}

		{ authType === 'saml2' && <>
			<FormTextField
				entry={'externalAuthentication_externalMetadataUrl'}
				form={form}
				label={'Metadata URL:'}
			/>
			<FormTextField
				entry={'externalAuthentication_logInRealm'}
				form={form}
				label={'Log in realm:'}
			/>
			<FormDropdownInput
				entry={'externalAuthentication_nameIdType'}
				form={form}
				label={'NameId type:'}
				options={nameIdTypeOptions}
			/>
			<CopyUnmanagedTextField
				copyLabel='Kopiëren'
				toastMessage='SAML2 manifest link gekopiëerd naar klembord.'
				label={'SAML2 manifest link:'}
				readonly
				value={internalMetadataInformation.saml2InternalMetaDataUrl}
			/>
		</> }

		{ authType === 'wsfed' && <>
			<FormTextField
				label={'Metadata URL:'}
				entry={'externalAuthentication_externalMetadataUrl'}
				form={form}
			/>
			<CopyUnmanagedTextField
				copyLabel='Kopiëren'
				toastMessage='WsFed manifest link gekopiëerd naar klembord.'
				label={'WsFed manifest link:'}
				readonly
				value={internalMetadataInformation.wsFedInternalMetaDataUrl}
			/>
		</> }

		<H3>Clients</H3>

		<FormCheckboxList
			label={'Frontend clients:'}
			form={form}
			entry={'frontendClientIds'}
			options={frontendClientOptions}
			placeholder={'Geen frontend clients beschikbaar.'}
		/>

		<FormCheckboxList
			label={'Backend clients:'}
			form={form}
			entry={'backendClientIds'}
			options={backendClientOptions}
			placeholder={'Geen backend clients beschikbaar.'}
		/>

		{ edit && <>
			<FormSeparator />

			<ButtonSet>
				<Button
					busy={form.busy}
					disabled={form.invalid}
					icon={OkIcon}
					label={'Opslaan'}
					priority={ButtonPriority.Primary}
					type={ButtonType.Submit}
				/>
				<Button
					icon={CancelIcon}
					label={'Annuleren'}
					type={ButtonType.Button}
					onClick={() => {
						form.reset();
						setEdit(false);
					}}
				/>
			</ButtonSet>
		</> }
	</Form>;
};
