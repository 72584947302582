import * as React from 'react';

import { Button, ButtonLayout, ButtonPriority, ButtonSet, ButtonType, FormLabel, FormLabelAs, FormLabelFor, Type } from '@docsys/controls';
import { useId } from '@docsys/hooks';
import { DeleteIcon } from '@docsys/icons';

import { AddPropertyButton } from './add-property-button';
import { EditPropertyButton } from './edit-property-button';
import { ScopePropertiesProps } from './form.types';

export const ScopeProperties: React.VFC<ScopePropertiesProps> = (props) => {
	const {
		entry: [properties, setProperties],
		readonly,
	} = props;

	const labelId = useId();

	const removeRow = (index: number) => {
		properties.splice(index, 1)
		setProperties(properties);
	};

	const handleUpdate = (index: number, type: string, value: string) => {
		const p = [...properties];
		p[index] = {
			type,
			value,
		};

		setProperties(p);
	};

	const handleAdd = (type: string, value: string) => {
		const p = [...properties, {
			type,
			value,
		}];

		setProperties(p);
	};

	return <div
		aria-labelledby={labelId}
		className={'form-row'}
		role="group"
	>
		<FormLabel
			className={'form-row__label'}
			id={labelId}
			labelFor={FormLabelFor.Other}
			text={'Eigenschappen:'}
			as={FormLabelAs.Div}
		/>
		<div className="form-row__control">
			{ properties.length > 0 && <table className="properties-table">
				<colgroup>
					<col style={{ width: '35%' }} />
					<col />
					<col style={{ width: 0 }} />
				</colgroup>
				<thead>
					<tr>
						<th scope='col'>Type:</th>
						<th scope='col'>Waarde:</th>
						{ !readonly && <th scope='col'><Type sr-only>Acties:</Type></th> }
					</tr>
				</thead>
				<tbody>
					{ properties.map((p, index) => <tr key={index}>
						<th scope='row'>{ p.type }</th>
						<td>{ p.value }</td>
						{ !readonly && <td>
							<ButtonSet>
								<EditPropertyButton
									index={index}
									type={p.type}
									value={p.value}
									onUpdate={handleUpdate}
								/>
								<Button
									icon={DeleteIcon}
									label={'Verwijderen'}
									layout={ButtonLayout.IconOnly}
									onClick={() => { removeRow(index); }}
									priority={ButtonPriority.Tertiary}
									type={ButtonType.Button}
								/>
							</ButtonSet>
						</td> }
					</tr>)}
				</tbody>
			</table> }
			{ !(properties?.length) && <span>Geen eigenschappen</span>  }

			{!readonly && <ButtonSet layout={{ marginBlockStart: 1 }}>
				<AddPropertyButton
					onAdd={handleAdd}
				/>
			</ButtonSet> }
		</div>
	</div>;
};
