import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AccessTokenContext } from '@docsys/auth';
import { Button, ButtonPriority, ButtonSet, ButtonType,  Form, FormSeparator, FormTextField, H2, Panel, PanelPriority } from '@docsys/controls';
import { useForm } from '@docsys/hooks';
import { AddIcon, CancelIcon } from '@docsys/icons';

import { displayNameValidator } from '~/components';
import { CreateTenantRequest, TenantsEndpoint } from '~/controllers';
import { useDisplayNamePlaceholder } from '~/hooks';

import { ErrorPage } from '../../error';
import { TenantsPage } from '../page';

export const AddTenant: React.VFC = () => {
	const tokenProvider = React.useContext(AccessTokenContext);
	const tenantsEndpoint = new TenantsEndpoint(tokenProvider);
	const displayNamePlaceholder = useDisplayNamePlaceholder();
	const [error, setError] = React.useState<Error | null>(null);

	const navigate = useNavigate();

	const form = useForm<CreateTenantRequest>({
		initialData: {
			displayName: '',
		},
		validations: {
			displayName: [displayNameValidator],
		},
		submit: async (data) => {
			try {
				const response = await tenantsEndpoint.createTenant(data);

				if (response.ok) {
					navigate('/tenants/tenant/' + response.tenantId);
				}

				return response;
			}
			catch (e) {
				setError(e as Error);
				throw e;
			}
		},
	});

	if (error) {
		return <ErrorPage error={error} />
	}

	return <TenantsPage>
		<Panel priority={PanelPriority.Primary}>
			<Form onSubmit={form.submit}>
				<H2 first>Tenant toevoegen</H2>
				<FormTextField
					label='Weergavenaam:'
					entry='displayName'
					placeholder={displayNamePlaceholder}
					form={form}
				/>
				<FormSeparator />
				<ButtonSet>
					<Button
						busy={form.busy}
						disabled={form.invalid}
						icon={AddIcon}
						label='Toevoegen'
						priority={ButtonPriority.Primary}
						type={ButtonType.Submit}
					/>
					<Button
						icon={CancelIcon}
						label='Annuleren'
						onClick={() => navigate(-1)}
						priority={ButtonPriority.Secondary}
						type={ButtonType.Button}
					/>
				</ButtonSet>
			</Form>
		</Panel>
	</TenantsPage>;
};
