import *  as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NotFoundErrorPage } from '../error/not-found';

import { AddClient } from './add';
import { ClientDetails } from './details';
import { ClientOverview } from './overview';

export const ClientRoutes: React.VFC = () => {
	return <Routes>
		<Route index element={<ClientOverview/>} />
		<Route path={'add/frontend'} element={<AddClient type={'frontend'} />} />
		<Route path={'add/backend'} element={<AddClient type={'backend'} />} />
		<Route path={'client/:id'} element={<ClientDetails />} />
		<Route path="*" element={<NotFoundErrorPage/>} />
	</Routes>;
};
