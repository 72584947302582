import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import { useForm, ValidationMessageType, Validator } from '@docsys/hooks';

import { TenantsResponse, TenantsEndpoint, ErrorResult } from '~/controllers';

interface useDeleteTenantFormOptions {
	tenant: TenantsResponse;
	onDeleted: (displayName: string) => void;
}

interface DeleteTenantFormData {
	displayName: string;
}

export const useDeleteTenantForm = (options: useDeleteTenantFormOptions) => {
	const {
		tenant,
		onDeleted,
	} = options;

	const tokenProvider = React.useContext(AccessTokenContext);
	const tenantsEndpoint = new TenantsEndpoint(tokenProvider);

	const displayNameValidator: Validator<string, DeleteTenantFormData> = {
		id: 'delete',
		validate: (value) => {
			return {
				type: value === tenant.displayName ? ValidationMessageType.Valid : ValidationMessageType.Invalid,
				text: 'Geef ter bevestiging de weergavenaam op van de tenant.',
			}
		},
	};

	const [error, setError] = React.useState<ErrorResult<never> | Error | null>(null);

	if (error) {
		// eslint-disable-next-line no-console -- Print error to console for debugging.
		console.log(error);
		throw error;
	}

	return useForm<DeleteTenantFormData>({
		initialData: {
			displayName: '',
		},
		validations: {
			displayName: [
				displayNameValidator,
			],
		},
		submit: async () => {
			try {
				const response = await tenantsEndpoint.deleteTenant(tenant.tenantId);

				if (response.ok) {
					window.setTimeout(() => {
						onDeleted(tenant.displayName);
					}, 10);
				}
				else
				{
					setError(response);
				}

				return response;
			}
			catch (e) {
				setError(e as Error);
				throw e;
			}
		},
	});
};
