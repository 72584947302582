import * as React from 'react';

import {
	Button,
	ButtonLayout,
	ButtonPriority,
	ButtonType,
	ToastDuration,
	UnmanagedTextField,
	UnmanagedTextFieldProps,
	useToast,
} from '@docsys/controls';
import { CopyIcon } from '@docsys/icons';

type CopyUnmanagedTextFieldProps = UnmanagedTextFieldProps & {
	copyLabel: string;
	toastMessage: string;
};

export const CopyUnmanagedTextField: React.VFC<CopyUnmanagedTextFieldProps> = (props) => {
	const {
		buttonsAfter,
		copyLabel,
		toastMessage,
		value,
		...otherProps
	} = props;

	const { showToast } = useToast();

	const handleCopyClick = () => {
		navigator.clipboard.writeText(value);

		showToast({
			text: toastMessage,
			duration: ToastDuration.Short,
			icon: CopyIcon,
			id: 'copy',
		});
	};

	return <UnmanagedTextField
		buttonsAfter={<>
			<Button
				icon={CopyIcon}
				layout={ButtonLayout.IconOnly}
				label={copyLabel}
				type={ButtonType.Button}
				onClick={handleCopyClick}
				priority={ButtonPriority.Tertiary}
			/>
			{buttonsAfter}
		</>}
		value={value}
		{...otherProps}
	/>;
};
