import * as React from 'react';

import { AuthConfig } from '@docsys/auth';
import { Alert, AlertType, Loading, LoadingVariant } from '@docsys/controls';

import { env } from '~/helpers';

interface AuthConfigProps {
	disableAuth?: boolean;
}

const Config: React.FC<AuthConfigProps> = (props) => {
	const {
		children,
	} = props;

	if (!env.appId) {
		return <Alert type={AlertType.Danger} text="appId not set" />;
	}

	return <AuthConfig appId={env.appId} configUrl={env.configUrl} loading={() => <Loading label={'Laden...'} variant={LoadingVariant.Large}/>}>
		{children}
	</AuthConfig>
};

export { Config as AuthConfig };
