import { ValidationMessageType } from '@docsys/hooks';

export const displayNameValidator = {
	id: 'displayname',
	validate(value: string) {
		if (!value || !value.trim()) {
			return {
				text: 'Weergavenaam is verplicht.',
				type: ValidationMessageType.Invalid,
			};
		}

		if (value.length > 1000) {
			return {
				text: 'Weergavenaam is te lang (' + value.length + ' tekens). Hou de weergavenaam korter dan 1000 tekens.',
				type: ValidationMessageType.Invalid,
			};
		}

		if (value.length > 100) {
			return {
				text: 'Weergavenaam is wel erg lang (' + value.length + ' tekens). Hou de weergavenaam kort.',
				type: ValidationMessageType.Warning,
			};
		}

		return {
			text: 'Weergavenaam is verplicht.',
			type: ValidationMessageType.Valid,
		};
	},
};
