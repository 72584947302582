import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import { Alert, AlertType, LinkButton, ButtonPriority, ButtonSet, Loading, LoadingVariant, H2 } from '@docsys/controls';
import { AddIcon, PreviousPageIcon } from '@docsys/icons';
import { ClientsEndpoint, ClientResponse, ErrorResult } from '~/controllers';

import { ErrorPage } from '../../error';
import { ClientList } from '../client-list';
import { ClientsPage } from '../page';

export const ClientOverview: React.VFC = () => {
	const tokenProvider = React.useContext(AccessTokenContext);

	const [frontendClients, setFrontendClients] = React.useState<ClientResponse[] | null>(null);
	const [backendClients, setBackendClients] = React.useState<ClientResponse[] | null>(null);
	const [error, setError] = React.useState<ErrorResult | null>(null);

	React.useEffect(() => {
		let clientsEndpoint = new ClientsEndpoint(tokenProvider);
		clientsEndpoint.getAllClients()
			.then(response => {
				if (response.ok) {
					setFrontendClients(response.clients.filter(c => c.type === 'frontend'));
					setBackendClients(response.clients.filter(c => c.type === 'backend'));
				}
				else {
					setError(response);
				}
			})
			.catch(e => {
				setError(e);
			});
	}, [tokenProvider]);

	if (error) {
		return <ErrorPage error={error} />
	}

	return <ClientsPage>
		<ButtonSet>
			<LinkButton to={'/'} icon={PreviousPageIcon} label={'Naar Index'} priority={ButtonPriority.Tertiary} />
		</ButtonSet>

		<hr />

		{ (!frontendClients || !backendClients) && <Loading label={'Laden...'} variant={LoadingVariant.Medium} /> }

		{ !!frontendClients && !!backendClients && <>
			<ButtonSet layout={{ marginBlockStart: 1, marginBlockEnd: 1 }} >
				<LinkButton to={'/clients/add/frontend'} icon={AddIcon} label={'Frontend client toevoegen'} priority={ButtonPriority.Primary} />
				<LinkButton to={'/clients/add/backend'} icon={AddIcon} label={'Backend client toevoegen'} priority={ButtonPriority.Primary} />
			</ButtonSet>
			<H2>Frontend</H2>
			{ frontendClients.length
				? <ClientList clients={frontendClients} />
				: <Alert type={AlertType.Info} text='Er zijn nog geen frontend clients.' />
			}
			<H2>Backend</H2>
			{ backendClients.length
				? <ClientList clients={backendClients} />
				: <Alert type={AlertType.Info} text='Er zijn nog geen backend clients.' />
			}
		</> }
	</ClientsPage>;
};
