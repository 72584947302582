import * as React from 'react';

import { Button, ButtonLayout, ButtonPriority, ButtonType } from '@docsys/controls';
import { EditIcon } from '@docsys/icons';

import { ScopePropertyDialog } from './scope-property-dialog';

interface EditPropertyButtonProps {
	index: number;
	type: string;
	value: string;
	onUpdate: (index: number, type: string, value: string) => void;
}

export const EditPropertyButton: React.VFC<EditPropertyButtonProps> = (props) => {
	const {
		index,
		type,
		value,
		onUpdate,
	} = props;

	const [open, setOpen] = React.useState<boolean>(false);

	const handleButtonClick = () => {
		setOpen(true);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	const handleDone = (index: number, type: string, value: string) => {
		onUpdate(index, type, value);
		setOpen(false);
	};

	return <>
		<Button
			icon={EditIcon}
			label={'Bewerken…'}
			layout={ButtonLayout.IconOnly}
			onClick={handleButtonClick}
			priority={ButtonPriority.Tertiary}
			type={ButtonType.Button}
		/>
		<ScopePropertyDialog
			open={open}
			onCancel={handleCancel}
			index={index}
			type={type}
			value={value}
			onDone={handleDone}
		/>
	</>;
};