import * as React from 'react';

import { Button, ButtonPriority, ButtonType } from '@docsys/controls';
import { RefreshIcon } from '@docsys/icons';

import { ClientResponse } from '~/controllers';

import { ResetSecretDialog } from './reset-secret-dialog';

interface ResetSecretButtonProps {
	client: ClientResponse,
}

export const ResetSecretButton: React.VFC<ResetSecretButtonProps> = (props) => {
	const {
		client,
	} = props;

	const [open, setOpen] = React.useState<boolean>(false);

	return <>
		<Button
			icon={RefreshIcon}
			label={'Secret opnieuw instellen'}
			onClick={() => setOpen(true)}
			type={ButtonType.Button}
			priority={ButtonPriority.Secondary}
		/>
		<ResetSecretDialog
			open={open}
			client={client}
			onClose={() => setOpen(false)}
		/>
	</>;
};