import * as React from 'react';

import { CoinList, CoinListLinkItem, CoinListType, CoinSize } from '@docsys/controls';
import { ScopeIcon } from '@docsys/icons';

interface Scope {
    name: string;
}

interface ScopesListProps {
    scopes: Scope[];
}

interface ScopesListItem extends CoinListLinkItem {
	// nothing
}

export const ScopeList: React.VFC<ScopesListProps> = (props) => {
	const {
		scopes,
	} = props;

	const items: ScopesListItem[] = scopes.map(t => ({
		icon: ScopeIcon,
		id: t.name,
		name: t.name,
		title: t.name,
		to: `/scopes/scope/${t.name}`,
	}));

	return <CoinList coinSize={CoinSize.ListDouble} items={items} type={CoinListType.Links} />;
};
