import *  as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NotFoundErrorPage } from '../error/not-found';

import { AddTenant } from './add';
import { TenantDetails } from './details';
import { TenantOverview } from './overview';

export const TenantRoutes: React.VFC = () => {
	return <Routes>
		<Route index element={<TenantOverview/>} />
		<Route path='add' element={<AddTenant/>} />
		<Route path='tenant/:id' element={<TenantDetails/>} />
		<Route path="*" element={<NotFoundErrorPage/>} />
	</Routes>;
};
