import { apiEndpoint } from '../app/api-endpoint';
import { ControllerBase, TokenProvider } from './base-controller';

interface GetAllScopesResponse {
	scopes: ScopeResponse[];
}

export interface ScopeResponse {
	name: string;
}

export interface DetailedScopeResponse extends ScopeResponse {
	properties: ScopeProperties;
	clients: string[];
}

export interface CreateScopeRequest {
	name: string;
}

interface CreateScopeResponse {
	name: string;
}

export interface UpdateScopeRequest {
	properties?: ScopeProperties;
	clients?: string[];
}

export type ScopeProperties = ScopeProperty[];

export type ScopeProperty = {
	type: string;
	value: string;
};

export class ScopesEndpoint extends ControllerBase {
	constructor(tokenProvider: TokenProvider) {
		super(apiEndpoint, tokenProvider);
	}

	async getAllScopes() {
		const result = await this.get<GetAllScopesResponse>('v1/scopes');

		if (result.ok) {
			result.scopes.sort((a, b) => a.name.localeCompare(b.name, 'nl', { ignorePunctuation: true, sensitivity: 'base', usage: 'sort' }));
		}

		return result;
	}

	getScopeDetails(name: string) {
		return this.get<DetailedScopeResponse>(`v1/scopes/${name}`);
	}

	createScope(request: CreateScopeRequest) {
		return this.post<CreateScopeRequest, CreateScopeResponse>('v1/scopes/', request);
	}

	updateScope(name: string, request: UpdateScopeRequest) {
		return this.patch<UpdateScopeRequest, never>(`v1/scopes/${name}`, request);
	}

	deleteScope(name: string) {
		return this.delete<never>(`v1/scopes/${name}`);
	}
}
