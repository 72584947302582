import * as React from 'react';

import { AccessTokenContext, EndpointsContext } from '@docsys/auth';
import { Id } from '@docsys/common-types';
import { Alert, AlertType, CoinList, CoinListItem, CoinListType, CoinSize } from '@docsys/controls';
import { TenantIcon } from '@docsys/icons';

interface Tenant {
    tenantId: Id;
    displayName: string;
    editable: boolean;
}

interface TenantListProps {
    tenants: Tenant[];
}

interface TenantListItem extends CoinListItem {
	// nothing
}

export const TenantList: React.VFC<TenantListProps> = (props) => {
	const {
		tenants,
	} = props;

	const endpointsContext = React.useContext(EndpointsContext);
	const tokenProvider = React.useContext(AccessTokenContext);

	const onTenantButtonClick = async (tenantID: string) => {
		let headers = new Headers();
		headers.append('Authorization', 'Bearer ' + await tokenProvider.getToken());
		if (endpointsContext.initialized) {
			await window
				.fetch(`${endpointsContext.endpoints.auth}connect/impersonate/${tenantID}`,{
					method: 'POST',
					credentials: 'include',
					headers,
				})
				.then(response => {
					if (response.ok) {
						const authEndpoint = endpointsContext.endpoints.auth.substring(0, endpointsContext.endpoints.auth.length - 1);
						window.location.href = `${authEndpoint.substring(0, authEndpoint.lastIndexOf('/'))}/${tenantID}`;
					}
				});
		}
	}

	//TODO: there should be a better way
	const ownTenantId = endpointsContext.initialized ? endpointsContext.endpoints.auth.split('/').at(-2) : '';

	const items: TenantListItem[] = tenants.filter(t => t.editable && t.tenantId != ownTenantId).map(t => ({
		id: t.tenantId,
		icon: TenantIcon,
		title: t.displayName,
		subtitle: t.tenantId,
	}));

	return items.length
		? <CoinList coinSize={CoinSize.ListDouble} items={items} onClick={async (item) => await onTenantButtonClick(item.id)} type={CoinListType.Buttons} />
		: <Alert type={AlertType.Info} text='Er zijn geen andere tenants.' />;
};
