import *  as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from '@docsys/controls';
import { Home, ClientRoutes, Impersonation, ScopeRoutes, TenantRoutes } from '~/pages';
import { NotFoundErrorPage } from '~/pages/error/not-found';

export const AppRoutes: React.VFC = () => {
	return <ErrorBoundary>
		<Routes>
			<Route path="" element={<Home/>} />
			<Route path="clients/*" element={<ClientRoutes />} />
			<Route path="scopes/*" element={<ScopeRoutes />} />
			<Route path="tenants/*" element={<TenantRoutes />} />
			<Route path="impersonation/*" element={<Impersonation />} />
			<Route path="*" element={<NotFoundErrorPage/>} />
		</Routes>
	</ErrorBoundary>;
};
