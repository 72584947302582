import { ValidationMessageType } from '@docsys/hooks';
import { validateUrl } from '~/helpers/url';


export const optionalHostnameValidator = {
	id: 'hostname',
	validate(value: string) {
		if (!value) {
			return null;
		}

		if (validateUrl(value)) {
			return null;
		}

		return {
			text: 'Geef een geldige URL op.',
			type: ValidationMessageType.Invalid,
		};
	},
};
