import { CheckboxListOption } from '@docsys/controls'
import { ValidationMessageType, Validator } from '@docsys/hooks';

import { DetailedClientResponse } from '~/controllers';
import { UpdateClientFormData } from './form.types';

const defaultBackendGrantTypes = [{
	value: 'client_credentials',
	label: 'Client credentials',
}, {
	value: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
	label: 'JWT bearer token',
}];

export function getGrantTypes(details: DetailedClientResponse):  CheckboxListOption<string>[] {
	const defaultValues = defaultBackendGrantTypes.map(d => d.value);

	const unknown = (details.grantTypes || [])
		.filter(g => !defaultValues.includes(g))
		.map(g => ({
			value: g,
			label: g,
		}));

	return [
		...defaultBackendGrantTypes,
		...unknown,
	];
}

export const grantTypeValidator: Validator<string[], UpdateClientFormData> = {
	id: 'grant-type',
	validate: function (value: string[]) {
		if (value.length === 0) {
			return {
				text: 'Selecteer minimaal één grant type.',
				type: ValidationMessageType.Warning,
			};
		}

		return null;
	},
};
