import { Id } from '@docsys/common-types';

import { apiEndpoint } from '../app/api-endpoint';
import { ControllerBase, TokenProvider } from './base-controller';

export type ClientType = 'frontend' | 'backend';

interface GetAllClientsResponse {
	clients: ClientResponse[];
}

export interface ClientResponse {
	id: Id;
	displayName: string;
	type: ClientType;
}

export interface DetailedClientResponse extends ClientResponse {
	grantTypes: string[];
	scopes: string[];
	tenants: string[];
	redirectUrlTemplate?: string;
}

export interface CreateClientRequest {
	displayName: string;
	type: ClientType
}

interface CreateClientResponse {
	id: Id;
	secret?: string;
}

export interface UpdateClientRequest {
	displayName: string;
	grantTypes?: string[];
	scopes?: string[];
	tenants?: string[];
	redirectUrlTemplate?: string;
}

interface ResetSecretResponse {
	id: Id;
	secret: string;
}

export class ClientsEndpoint extends ControllerBase {
	constructor(tokenProvider: TokenProvider) {
		super(apiEndpoint, tokenProvider);
	}

	async getAllClients() {
		const result = await this.get<GetAllClientsResponse>('v1/clients');

		if (result.ok) {
			result.clients.sort((a, b) => a.displayName.localeCompare(b.displayName, 'nl', { ignorePunctuation: true, sensitivity: 'base', usage: 'sort' }));
		}

		return result;
	}

	getClientDetails(id: Id) {
		return this.get<DetailedClientResponse>(`v1/clients/${id}`);
	}

	createClient(request: CreateClientRequest) {
		return this.post<CreateClientRequest, CreateClientResponse>('v1/clients/', request);
	}

	updateClient(id: Id, request: UpdateClientRequest) {
		return this.patch<UpdateClientRequest, never>(`v1/clients/${id}`, request);
	}

	resetSecret(id: Id) {
		return this.post<undefined, ResetSecretResponse>(`v1/clients/${id}/secret`, undefined);
	}

	deleteClient(id: Id) {
		return this.delete<never>(`v1/clients/${id}`);
	}
}
