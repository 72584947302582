import * as React from 'react';


import { Alert, AlertType, LinkButton, ButtonPriority, ButtonSet, Content, H1 } from '@docsys/controls';
import { CrashIcon } from '@docsys/icons';
import { ErrorResult } from '~/controllers';

interface ErrorPageProps<T> {
	error: ErrorResult<T> | Error;
}

function ErrorPage<T>(props: ErrorPageProps<T>) {
	const {
		error,
	} = props;

	const message = ('clientMessage' in error && typeof error.clientMessage === 'string') ? error.clientMessage : 'Er ging iets mis.';

	return <Content>
		<H1 first>Beheer</H1>

		<Alert
			type={AlertType.Danger}
			icon={CrashIcon}
			text={message}
		/>

		<hr />

		<ButtonSet>
			<LinkButton
				to={'/'}
				label={'Naar hoofdpagina'}
				priority={ButtonPriority.Primary}
			/>
		</ButtonSet>
	</Content>;
}

export { ErrorPage };
