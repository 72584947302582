import { ValidationMessageType, Validator } from '@docsys/hooks';
import { validateUrl } from '~/helpers';
import { UpdateClientFormData } from './form.types';

export const redirectUrlTemplateDefault = 'https://{host}/auth/login-callback';

const examples = {
	host: 'example.com:8080',
};

// ok, invalid parts, invalid character
type ValidateTemplateResult = true | string[] | string;

function validateTemplate(template: string): ValidateTemplateResult {
	const replaced = template.replace(/\{([0-9a-zA-Z_ ]+)\}/g, '');

	if (replaced.includes('{')) {
		return '{';
	}

	if (replaced.includes('}')) {
		return '}';
	}

	const regex = /\{([0-9a-zA-Z_ ]+)\}/g;
	const result = template.match(regex);

	if (result?.length) {
		return result
			.map(p => p.replace(/[{}]/g, ''))
			.map(p => p.split(' ')[0])
			.filter(p => !(p in examples))
			.map(p => `{${p}}`);
	}

	return true;
}

function applyTemplate(template: string) {
	return template.replace(/\{([0-9a-zA-Z_ ]+)\}/g, (match, i, index) => {
		if (template[index - 1] === '{' && template[index + match.length] === '}') {
			return i;
		}

		return examples[i] || i;
	});
}

export const redirectUrlTemplateValidator: Validator<string, UpdateClientFormData> = {
	id: 'url',
	validate: function (value: string) {
		const descriptionResult = {
			text: `Laat leeg om de standaardwaarde te gebruiken. Je kan de volgende symbolen gebruiken: ${Object.keys(examples).map(k => `{${k}}`)}.`,
			type: ValidationMessageType.Description,
		};

		const validateTemplateResult = validateTemplate(value || redirectUrlTemplateDefault);

		if (Array.isArray(validateTemplateResult)) {
			if (validateTemplateResult.length === 1) {
				const invalidSymbolResult = {
					text: 'Onbekend symbool ' + validateTemplateResult[0],
					type: ValidationMessageType.Invalid,
				};

				return [invalidSymbolResult, descriptionResult];
			}
			else if (validateTemplateResult.length > 1) {
				const invalidSymbolResult = {
					text: 'Onbekende symbolen: ' + validateTemplateResult.join(', '),
					type: ValidationMessageType.Invalid,
				};

				return [invalidSymbolResult, descriptionResult];
			}
		}

		if (typeof validateTemplateResult === 'string' && validateTemplateResult.length > 0) {
			const invalidCharacterResult = {
				text: 'Ongeldig karakter: ' + validateTemplateResult,
				type: ValidationMessageType.Invalid,
			};

			return [invalidCharacterResult, descriptionResult];
		}

		const example = applyTemplate(value || redirectUrlTemplateDefault);

		if (!validateUrl(example)) {
			const invalidUrlResult = {
				text: 'Geef een geldige URL op.',
				type: ValidationMessageType.Invalid,
			};

			return [invalidUrlResult, descriptionResult];
		}

		const exampleResult = {
			text: 'Voorbeeld: ' + example,
			type: ValidationMessageType.Description,
		};

		return [exampleResult, descriptionResult];
	},
};
