import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import { Id } from '@docsys/common-types';
import { ToastDuration, useToast } from '@docsys/controls';
import { useForm } from '@docsys/hooks';

import { displayNameValidator, hostnamesValidator, optionalHostnameValidator } from '~/components';
import { ClientResponse, DetailedTenantResponse, ExternalAuthenticationRequest, TenantsEndpoint, UpdateTenantRequest } from '~/controllers';

import { UpdateTenantFormData } from './form.types';


export const useUpdateTenantForm = (details: DetailedTenantResponse, clients: { frontend: ClientResponse[], backend: ClientResponse[] }) => {

	const tokenProvider = React.useContext(AccessTokenContext);
	const tenantsEndpoint = new TenantsEndpoint(tokenProvider);

	const [edit, setEdit] = React.useState<boolean>(false);

	const { showToast } = useToast();

	const form = useForm<UpdateTenantFormData>({
		initialData: {
			tenantId: details.tenantId,
			displayName: details.displayName,
			hostnames: details.hostnames,
			frontendClientIds: details.clientIds.filter(id => clients.frontend.some(fc => fc.id === id)),
			backendClientIds: details.clientIds.filter(id => clients.backend.some(fc => fc.id === id)),
			externalAuthentication_type: details.externalAuthentication.type || '',
			externalAuthentication_externalClientId: ('externalClientId' in details.externalAuthentication ? details.externalAuthentication.externalClientId : ''),
			externalAuthentication_externalMetadataUrl: details.externalAuthentication.externalMetadataUrl || '',
			externalAuthentication_nameIdType: ('nameIdType' in details.externalAuthentication ? details.externalAuthentication.nameIdType : 'unspecified'),
			externalAuthentication_logInRealm: ('logInRealm' in details.externalAuthentication ? details.externalAuthentication.logInRealm : ''),
			externalAuthentication_clientSecret: '',
			connectionString: details.connectionString,
		},
		validations: {
			displayName: [displayNameValidator],
			hostnames: [hostnamesValidator],
			externalAuthentication_externalMetadataUrl: [optionalHostnameValidator],
		},
		submit: async (data) => {
			const buildExternalAuthentication: (data: UpdateTenantFormData) => ExternalAuthenticationRequest = (data) => {
				switch (data.externalAuthentication_type) {
					case 'oidc':
						return {
							type: 'oidc',
							clientSecret: data.externalAuthentication_clientSecret || null,
							externalClientId: data.externalAuthentication_externalClientId,
							externalMetaDataUrl: data.externalAuthentication_externalMetadataUrl,
						};

					case 'saml2':
						return {
							type: 'saml2',
							externalMetaDataUrl: data.externalAuthentication_externalMetadataUrl,
							logInRealm: data.externalAuthentication_logInRealm,
							nameIdType: data.externalAuthentication_nameIdType,
						};

					case 'wsfed':
						return {
							type: 'wsfed',
							externalMetaDataUrl: data.externalAuthentication_externalMetadataUrl,
						};
				}
			};

			const request: UpdateTenantRequest = {
				displayName: data.displayName,
				hostnames: data.hostnames,
				clientIds: [...data.frontendClientIds, ...data.backendClientIds],
				externalAuthentication: buildExternalAuthentication(data),
			};

			let result = await tenantsEndpoint.updateTenant(data.tenantId as Id, request);

			if (result.ok) {
				setEdit(false);
				showToast({
					text: 'Wijzigingen opgeslagen.',
					duration: ToastDuration.Short,
				});
			}

			return result;
		},
	});

	return {
		form,
		edit,
		setEdit,
	};
};
