import * as React from 'react';

import {
	ButtonPriority,
	ButtonSet,
	Content,
	H1,
	LinkButton,
} from '@docsys/controls';

export function NotFoundErrorPage() {
	return <Content>
		<H1 first>404 - Pagina niet gevonden</H1>

		<ButtonSet>
			<LinkButton
				to={'/'}
				label={'Naar hoofdpagina'}
				priority={ButtonPriority.Primary}
			/>
		</ButtonSet>
	</Content>;
}

