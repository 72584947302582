import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Auth } from '@docsys/auth';
import { Alert, AlertType, Loading, LoadingVariant, Page } from '@docsys/controls';

import { AppRoutes } from './app-routes';
import { AppTheme } from './app-theme';
import { AuthConfig } from './auth-config';

export const App: React.VFC = () => {
	return <Page>
		<Router>
			<AuthConfig>
				<AppTheme>
					<Auth
						allowRedirect
						error={() => <Alert type={AlertType.Danger} text="Access denied" />}
						loading={() => <Loading label={'Laden...'} variant={LoadingVariant.Large} />}
						scopes={['docsys:management']}
						optionalScopes={['docsys:impersonation']}
					>
						<AppRoutes />
					</Auth>
				</AppTheme>
			</AuthConfig>
		</Router>
	</Page>;
};
