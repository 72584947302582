declare let process : {
	env: Record<string, any>
}

const variables = process.env;

function get<T>(key: string): T {
	return variables[key] as unknown as T;
}

export const env = {
	appId: get<string>('APP_ID') || null,
	configUrl: get<string>('CONFIG_URL') || 'https://config.docsys.dev',
};