import * as React from 'react';


import { Id } from '@docsys/common-types';
import { CoinList, CoinListLinkItem, CoinListType, CoinSize } from '@docsys/controls';
import { ServerIcon, WebBrowserIcon } from '@docsys/icons';
import { ClientType } from '~/controllers';

interface Client {
    id: Id;
    displayName: string;
	type: ClientType;
}

interface ClientListProps {
    clients: Client[];
}

interface ClientListItem extends CoinListLinkItem {
	// nothing
}

export const ClientList: React.VFC<ClientListProps> = (props) => {
	const {
		clients,
	} = props;

	const items: ClientListItem[] = clients.map(t => ({
		id: t.id,
		icon: t.type === 'frontend' ? WebBrowserIcon : ServerIcon,
		title: t.displayName,
		subtitle: t.id,
		to: `/clients/client/${t.id}`,
	}));

	return <CoinList coinSize={CoinSize.ListDouble} items={items} type={CoinListType.Links} />;
};
