import { useForm } from '@docsys/hooks';

import { UpdateScopeProperty } from './form.types';

interface useScopePropertyFormOptions {
	type: string;
	value: string;
	onSubmit: (key: string, value: string) => void;
}

export const useScopePropertyForm = (options: useScopePropertyFormOptions) => {
	const {
		type,
		value,
		onSubmit,
	} = options;

	return useForm<UpdateScopeProperty>({
		initialData: {
			type: type || '',
			value: value || '',
		},
		submit: async (data) => {
			onSubmit(data.type, data.value);
			return {
				ok: true,
			};
		},
	});
};
