import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonPriority, ButtonType, useToast } from '@docsys/controls';
import { DeleteIcon } from '@docsys/icons';

import { TenantsResponse } from '~/controllers';

import { DeleteTenantDialog } from './delete-tenant-dialog';

interface DeleteTenantButtonProps {
	tenant: TenantsResponse,
}

export const DeleteTenantButton: React.VFC<DeleteTenantButtonProps> = (props) => {
	const {
		tenant,
	} = props;

	const { showToast } = useToast();

	const [open, setOpen] = React.useState<boolean>(false);
	const navigate = useNavigate();

	const handleButtonClick = () => {
		setOpen(true);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	const handleDeleted = (displayName: string) => {
		setOpen(false);

		showToast({
			text: `Tenant "${displayName}" is verwijderd.`,
		});

		window.setTimeout(() => {
			navigate('/tenants');
		}, 500);
	};

	return <>
		<Button
			icon={DeleteIcon}
			label={'Verwijderen'}
			priority={ButtonPriority.SecondaryDanger}
			onClick={handleButtonClick}
			type={ButtonType.Button}
		/>
		<DeleteTenantDialog
			open={open}
			tenant={tenant}
			onCancel={handleCancel}
			onDeleted={handleDeleted}
		/>
	</>;
};