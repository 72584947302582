import * as React from 'react';

import { AccessTokenContext } from '@docsys/auth';
import { useForm, ValidationMessageType, Validator } from '@docsys/hooks';

import { ScopeResponse, ScopesEndpoint, ErrorResult } from '~/controllers';

interface useDeleteScopeFormOptions {
	scope: ScopeResponse;
	onDeleted: (displayName: string) => void;
}

interface DeleteScopeFormData {
	name: string;
}

export const useDeleteScopeForm = (options: useDeleteScopeFormOptions) => {
	const {
		scope,
		onDeleted,
	} = options;

	const tokenProvider = React.useContext(AccessTokenContext);
	const scopesEndpoint = new ScopesEndpoint(tokenProvider);

	const nameValidator: Validator<string, DeleteScopeFormData> = {
		id: 'delete',
		validate: (value) => {
			return {
				type: value === scope.name ? ValidationMessageType.Valid : ValidationMessageType.Invalid,
				text: 'Geef ter bevestiging de naam op van de scope.',
			}
		},
	};

	const [error, setError] = React.useState<ErrorResult<never> | Error | null>(null);

	if (error) {
		// eslint-disable-next-line no-console -- Print error to console for debugging.
		console.log(error);
		throw error;
	}

	return useForm<DeleteScopeFormData>({
		initialData: {
			name: '',
		},
		validations: {
			name: [
				nameValidator,
			],
		},
		submit: async () => {
			try {
				const response = await scopesEndpoint.deleteScope(scope.name);

				if (response.ok) {
					window.setTimeout(() => {
						onDeleted(scope.name);
					}, 10);
				}
				else
				{
					setError(response);
				}

				return response;
			}
			catch (e) {
				setError(e as Error);
				throw e;
			}
		},
	});
};
