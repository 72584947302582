import * as React from 'react';


import { OfType } from '@docsys/common-types';
import { FormListInput } from '@docsys/controls';
import { FormRecord, UseFormResult, ValidationMessageType } from '@docsys/hooks';
import { validateHost } from '~/helpers';

interface FormHostProps<F extends FormRecord, K extends keyof OfType<F, string[]> = keyof OfType<F, string[]>> {
	form: UseFormResult<F>,
	entry: K;
	readonly?: boolean;
}

const validateHostValue = (host: string, otherHosts: string[]) => validateHost(host) && !otherHosts.includes(host);

export const FormHost = <F extends FormRecord>(props: FormHostProps<F>) => {
	const {
		entry,
		form,
		readonly,
	} = props;

	return <FormListInput
		addButtonLabel={'Hostname toevoegen'}
		addInputLabel={'Hostname toevoegen:'}
		deleteButtonLabel={'Hostname verwijderen'}
		editButtonLabel={'Hostname aanpassen'}
		editCancelButtonLabel={'Annuleren'}
		editInputLabel={'Hostname:'}
		editOkButtonLabel={'Aanpassingen opslaan'}
		emptyText={'Geen hostnames toegevoegd'}
		entry={entry}
		form={form}
		label='Hostnames:'
		placeholder={'example.com:44300'}
		readonly={readonly}
		validateItem={validateHostValue}
	/>
};

export const hostnamesValidator = {
	id: 'hostname',
	validate(value: string[]) {
		if (value.every(v => validateHost(v))) {
			return null;
		}

		return {
			text: value.find(v => !validateHost(v)) + ' is geen geldige hostname. Gebruik geen protocol en laat de poort weg als deze 443 is.',
			type: ValidationMessageType.Invalid,
		};
	},
};
