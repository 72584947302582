import * as React from 'react';

import {
	Button,
	ButtonLayout,
	ButtonPriority,
	ButtonType,
	FormTextField,
	FormTextFieldProps,
	ToastDuration,
	useToast,
} from '@docsys/controls';
import { FormRecord } from '@docsys/hooks';
import { CopyIcon } from '@docsys/icons';

type CopyFormTextFieldProps<F extends FormRecord> = FormTextFieldProps<F> & {
	copyLabel: string;
	toastMessage: string;
};

export function CopyFormTextField<F extends FormRecord>(props: CopyFormTextFieldProps<F>) {
	const {
		buttonsAfter,
		copyLabel,
		toastMessage,
		form,
		entry,
		...otherProps
	} = props;

	const { showToast } = useToast();

	const handleCopyClick = () => {
		const value = form.getEntry<string>(entry).value[0];

		navigator.clipboard.writeText(value);

		showToast({
			text: toastMessage,
			duration: ToastDuration.Short,
			icon: CopyIcon,
			id: 'copy',
		});
	};

	return <FormTextField
		buttonsAfter={<>
			<Button
				icon={CopyIcon}
				layout={ButtonLayout.IconOnly}
				label={copyLabel}
				type={ButtonType.Button}
				onClick={handleCopyClick}
				priority={ButtonPriority.Tertiary}
			/>
			{buttonsAfter}
		</>}
		form={form}
		entry={entry}
		{...otherProps}
	/>;
}
